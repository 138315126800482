import treeCreation from './treeCreation';
import treeAlterationSuit, { treeAlterationSuitInverse } from './treeAlterationSuit';
import pant from '../../assets/order/retouches/pants.jpg';
import mant from '../../assets/order/retouches/coat.jpg';
import robe from '../../assets/order/retouches/dress.jpg';
import haut from '../../assets/order/retouches/shirt.jpg';
import jupe from '../../assets/order/retouches/skirt.jpg';
import pull from '../../assets/order/retouches/pullover.jpg';
import mari from '../../assets/order/retouches/wedding.jpg';
import acce from '../../assets/order/retouches/accessories.jpg';
import mais from '../../assets/order/retouches/linens.jpg';
import ling from '../../assets/order/retouches/lingerie.jpg';
import mail from '../../assets/order/retouches/shorts.jpg';
import cost from '../../assets/order/retouches/suit.jpg';
import comb from '../../assets/order/retouches/overall.jpg';

const tree = [
  {
    name: 'Pantalon',
    slug: 'pant',
    image: pant,
    typesOfWork: [
      {
        label: 'Retouche',
        sublabel: 'Ajuster la taille, réparer',
        slug: 'alteration',
        problems: [
          {
            slug: 'tooLarge',
            label: 'Il est trop grand',
            question: 'À quel endroit est-il trop grand ?',
            locations: [
              {
                slug: 'tooLargeleg',
                label: 'Sur les jambes',
                simpleAlterationId: '7.pant-cint-2',
                complexAlterationId: '7.pant-cint-6',
              },
              {
                slug: 'tooLargecrotch',
                label: 'A l’entrejambe',
                simpleAlterationId: '7.pant-cint-4',
                complexAlterationId: '7.pant-cint-8',
              },
              {
                slug: 'tooLargebelt',
                label: 'A la ceinture',
                simpleAlterationId: '7.pant-cint-1',
                complexAlterationId: '7.pant-cint-5',
              },
              {
                slug: 'tooLargelength',
                label: 'Sur la longueur',
                simpleAlterationId: '7.pant-rac-1',
                complexAlterationId: '7.pant-rac-3',
              },
            ],
          },
          {
            slug: 'tooSmall',
            label: 'Il est trop petit',
            question: 'À quel endroit est-il trop petit ?',
            locations: [
              {
                slug: 'tooSmallleg',
                label: 'Sur les jambes',
                simpleAlterationId: '7.pant-ela-2',
                complexAlterationId: '7.pant-ela-6',
              },
              {
                slug: 'tooSmallcrotch',
                label: 'A l’entrejambe',
                simpleAlterationId: '7.pant-ela-4',
                complexAlterationId: '7.pant-ela-8',
              },
              {
                slug: 'tooSmallbelt',
                label: 'A la ceinture',
                simpleAlterationId: '7.pant-ela-1',
                complexAlterationId: '7.pant-ela-5',
              },
              {
                slug: 'tooSmalllength',
                label: 'Sur la longueur',
                simpleAlterationId: '7.pant-ral-1',
                complexAlterationId: '7.pant-ral-2',
              },
            ],
          },
          {
            slug: 'damaged',
            label: 'Il est abîmé',
            question: 'À quel endroit est-il abîmé ?',
            locations: [
              {
                slug: 'pocket',
                label: 'Une poche est trouée',
                simpleAlterationId: '7.pant-div-4',
                complexAlterationId: '7.pant-div-15',
                multiple: true,
              },
              {
                slug: 'button',
                label: 'Il manque un bouton',
                simpleAlterationId: '7.pant-div-6',
                complexAlterationId: '7.pant-div-18',
                multiple: true,
              },
              {
                slug: 'brokenZip',
                label: 'Le zip est bloqué',
                simpleAlterationId: '7.pant-div-1',
                complexAlterationId: '7.pant-div-13',
                multiple: true,
              },
              {
                slug: 'tornHoley',
                label: 'Il est déchiré / troué',
                simpleAlterationId: '7.pant-div-7',
                complexAlterationId: '7.pant-div-19',
                multiple: true,
              },
            ],
          },
          {
            slug: 'hem',
            label: 'Faire un ourlet',
            question: 'À quel endroit est-il trop grand ?',
            locations: [
              {
                slug: 'hem',
                label: 'Sur la longueur',
                simpleAlterationId: '7.pant-rac-1',
                complexAlterationId: '7.pant-rac-3',
              },
            ],
          },
          {
            slug: 'talkRDV',
            label: 'En parler en RDV',
          },
        ],
      },
      {
        label: 'Broderie',
        sublabel: 'Décorer à l’aide d’un motif',
        slug: 'embroidery',
      },
      {
        label: 'Upcycling',
        sublabel: 'Transformer l’aspect initial',
        slug: 'transformation',
      },
      {
        label: 'Création d’accessoires',
        sublabel: 'Récupérer pour créer',
        slug: 'creation',
      },
    ],
  },
  {
    name: 'Manteau & veste',
    slug: 'mant',
    image: mant,
    typesOfWork: [
      {
        label: 'Retouche',
        sublabel: 'Ajuster la taille, réparer',
        slug: 'alteration',
        problems: [
          {
            slug: 'tooLarge',
            label: 'Il est trop grand',
            question: 'À quel endroit est-il trop grand ?',
            locations: [
              {
                slug: 'longArm',
                label: 'Manches trop longues',
                simpleAlterationId: '7.mant-rac-2',
                complexAlterationId: '7.mant-rac-2',
              },
              {
                slug: 'tooLong',
                label: 'Il est trop long',
                simpleAlterationId: '7.mant-rac-1',
                complexAlterationId: '7.mant-rac-1',
              },
              {
                slug: 'largeChest',
                label: 'Buste trop large',
                simpleAlterationId: '7.mant-cin-4',
                complexAlterationId: '7.mant-cin-4',
              },
              {
                slug: 'largeArm',
                label: 'Manches trop larges',
                simpleAlterationId: '7.mant-cin-2',
                complexAlterationId: '7.mant-cin-2',
              },
              {
                slug: 'largeShoulder',
                label: 'Épaules trop larges',
                simpleAlterationId: '7.mant-cin-1',
                complexAlterationId: '7.mant-cin-1',
              },
            ],
          },
          {
            slug: 'tooSmall',
            label: 'Il est trop petit',
            question: 'À quel endroit est-il trop petit ?',
            locations: [
              {
                slug: 'shortArm',
                label: 'Manches trop courtes',
                simpleAlterationId: '7.mant-ral-2',
                complexAlterationId: '7.mant-ral-2',
              },
              {
                slug: 'shortChest',
                label: 'Buste trop étroit',
                simpleAlterationId: '7.mant-ela-4',
                complexAlterationId: '7.mant-ela-4',
              },
              {
                slug: 'tooShort',
                label: 'Il est trop court',
                simpleAlterationId: '7.mant-ral-1',
                complexAlterationId: '7.mant-ral-1',
              },
              {
                slug: 'armTooTight',
                label: 'Manches trop serrées',
                simpleAlterationId: '7.mant-ela-2',
                complexAlterationId: '7.mant-ela-2',
              },
              {
                slug: 'shoulderTooTight',
                label: 'Épaules trop serrées',
                simpleAlterationId: '7.mant-ela-1',
                complexAlterationId: '7.mant-ela-1',
              },
            ],
          },
          {
            slug: 'damaged',
            label: 'Il est abîmé',
            question: 'À quel endroit est-il abîmé ?',
            locations: [
              {
                slug: 'entireLining',
                label: 'Sur toute la doublure',
                simpleAlterationId: '7.mant-div-1',
                complexAlterationId: '7.mant-div-1',
              },
              {
                slug: 'liningPart',
                label: 'Sur une partie de la doublure',
                simpleAlterationId: '7.mant-div-2',
                complexAlterationId: '7.mant-div-2',
              },
              {
                slug: 'pocket',
                label: 'Une poche est trouée',
                simpleAlterationId: '7.mant-div-7',
                complexAlterationId: '7.mant-div-7',
                multiple: true,
              },
              {
                slug: 'button',
                label: 'Il manque un bouton',
                simpleAlterationId: '7.mant-div-12',
                complexAlterationId: '7.mant-div-12',
                multiple: true,
              },
              {
                slug: 'brokenZip',
                label: 'Le zip est bloqué',
                simpleAlterationId: '7.mant-div-5',
                complexAlterationId: '7.mant-div-5',
                multiple: true,
              },
              {
                slug: 'tornHoley',
                label: 'Il est déchiré / troué',
                simpleAlterationId: '7.mant-div-13',
                complexAlterationId: '7.mant-div-13',
                multiple: true,
              },
              {
                slug: 'reinforcement',
                label: 'Il nécessite un renfort',
                simpleAlterationId: '7.mant-div-17',
                complexAlterationId: '7.mant-div-17',
                multiple: true,
              },
            ],
          },
          {
            slug: 'talkRDV',
            label: 'En parler en RDV',
          },
        ],
      },
      {
        label: 'Broderie',
        sublabel: 'Décorer à l’aide d’un motif',
        slug: 'embroidery',
      },
      {
        label: 'Upcycling',
        sublabel: 'Transformer l’aspect initial',
        slug: 'transformation',
      },
      {
        label: 'Création d’accessoires',
        sublabel: 'Récupérer pour créer',
        slug: 'creation',
      },
    ],
  },
  {
    name: 'Robe',
    slug: 'robe',
    image: robe,
    typesOfWork: [
      {
        label: 'Retouche',
        sublabel: 'Ajuster la taille, réparer',
        slug: 'alteration',
        problems: [
          {
            slug: 'tooLarge',
            label: 'Elle est trop grande',
            question: 'À quel endroit est-elle trop grande ?',
            locations: [
              {
                slug: 'tooLarge',
                label: 'Elle est trop large',
                simpleAlterationId: '7.robe-cin-1',
                complexAlterationId: '7.robe-cin-7',
              },
              {
                slug: 'tooLong',
                label: 'Elle est trop longue',
                simpleAlterationId: '7.robe-rac-1',
                complexAlterationId: '7.robe-rac-7',
              },
              {
                slug: 'longArm',
                label: 'Manches trop longues',
                simpleAlterationId: '7.robe-rac-4',
                complexAlterationId: '7.robe-rac-10',
              },
              {
                slug: 'largeArm',
                label: 'Manches trop larges',
                simpleAlterationId: '7.robe-cin-3',
                complexAlterationId: '7.robe-cin-9',
              },
              {
                slug: 'tooLargesling',
                label: 'Au niveau des bretelles',
                simpleAlterationId: '7.robe-rac-6',
                complexAlterationId: '7.robe-rac-12',
              },
              {
                slug: 'largeShoulder',
                label: 'Épaules trop large',
                simpleAlterationId: '7.robe-cin-2',
                complexAlterationId: '7.robe-cin-8',
              },
              {
                slug: 'largeChest',
                label: 'Buste trop large',
                simpleAlterationId: '7.robe-cin-5',
                complexAlterationId: '7.robe-cin-11',
              },
            ],
          },
          {
            slug: 'tooSmall',
            label: 'Elle est trop petite',
            question: 'À quel endroit est-elle trop petite ?',
            locations: [
              {
                slug: 'tooShort',
                label: 'Elle est trop courte',
                simpleAlterationId: '7.robe-ral-1',
                complexAlterationId: '7.robe-ral-7',
              },
              {
                slug: 'shortArm',
                label: 'Manches trop courtes',
                simpleAlterationId: '7.robe-ral-4',
                complexAlterationId: '7.robe-ral-10',
              },
              {
                slug: 'armTooTight',
                label: 'Manches trop sérrées',
                simpleAlterationId: '7.robe-ela-3',
                complexAlterationId: '7.robe-ela-9',
              },
              {
                slug: 'tooSmallsling',
                label: 'Au niveau des bretelles',
                simpleAlterationId: '7.robe-ral-6',
                complexAlterationId: '7.robe-ral-12',
              },
              {
                slug: 'smallShoulder',
                label: 'Épaules trop étroite',
                simpleAlterationId: '7.robe-ela-2',
                complexAlterationId: '7.robe-ela-8',
              },
              {
                slug: 'smallChest',
                label: 'Buste trop étroit',
                simpleAlterationId: '7.robe-ela-5',
                complexAlterationId: '7.robe-ela-11',
              },
            ],
          },
          {
            slug: 'damaged',
            label: 'Elle est abîmée',
            question: 'À quel endroit est-elle abîmée ?',
            locations: [
              {
                slug: 'button',
                label: 'Il manque un bouton',
                simpleAlterationId: '7.robe-div-2',
                complexAlterationId: '7.robe-div-26',
                multiple: true,
              },
              {
                slug: 'brokenZip',
                label: 'Le zip est bloqué',
                simpleAlterationId: '7.robe-div-2',
                complexAlterationId: '7.robe-div-17',
                multiple: true,
              },
              {
                slug: 'tornHoley',
                label: 'Elle est déchirée / trouée',
                simpleAlterationId: '7.robe-div-11',
                complexAlterationId: '7.robe-div-28',
                multiple: true,
              },
            ],
          },
          {
            slug: 'hem',
            label: 'Faire un ourlet',
            question: 'À quel endroit est-elle trop grande ?',
            locations: [
              {
                slug: 'hem',
                label: 'Sur la longueur',
                simpleAlterationId: '7.robe-rac-1',
                complexAlterationId: '7.robe-rac-7',
              },
            ],
          },
          {
            slug: 'talkRDV',
            label: 'En parler en RDV',
          },
        ],
      },
      {
        label: 'Broderie',
        sublabel: 'Décorer à l’aide d’un motif',
        slug: 'embroidery',
      },
      {
        label: 'Upcycling',
        sublabel: 'Transformer l’aspect initial',
        slug: 'transformation',
      },
      {
        label: 'Création d’accessoires',
        sublabel: 'Récupérer pour créer',
        slug: 'creation',
      },
    ],
  },
  {
    name: 'Haut',
    slug: 'haut',
    image: haut,
    typesOfWork: [
      {
        label: 'Retouche',
        sublabel: 'Ajuster la taille, réparer',
        slug: 'alteration',
        problems: [
          {
            slug: 'tooLarge',
            label: 'Il est trop grand',
            question: 'À quel endroit est-il trop grand ?',
            locations: [
              {
                slug: 'longArm',
                label: 'Manches trop longues',
                simpleAlterationId: '7.haut-rac-2',
                complexAlterationId: '7.haut-rac-5',
              },
              {
                slug: 'tooLong',
                label: 'Il est trop long',
                simpleAlterationId: '7.haut-rac-1',
                complexAlterationId: '7.haut-rac-4',
              },
              {
                slug: 'largeChest',
                label: 'Buste trop large',
                simpleAlterationId: '7.haut-cin-4',
                complexAlterationId: '7.haut-cin-8',
              },
              {
                slug: 'largeArm',
                label: 'Manches trop larges',
                simpleAlterationId: '7.haut-cin-2',
                complexAlterationId: '7.haut-cin-6',
              },
              {
                slug: 'largeShoulder',
                label: 'Épaules trop larges',
                simpleAlterationId: '7.haut-cin-1',
                complexAlterationId: '7.haut-cin-5',
              },
            ],
          },
          {
            slug: 'tooSmall',
            label: 'Il est trop petit',
            question: 'À quel endroit est-il trop petit ?',
            locations: [
              {
                slug: 'shortArm',
                label: 'Manches trop courtes',
                simpleAlterationId: '7.haut-ral-2',
                complexAlterationId: '7.haut-ral-5',
              },
              {
                slug: 'shortChest',
                label: 'Buste trop étroit',
                simpleAlterationId: '7.haut-ela-4',
                complexAlterationId: '7.haut-ela-8',
              },
              {
                slug: 'tooShort',
                label: 'Il est trop court',
                simpleAlterationId: '7.haut-ral-1',
                complexAlterationId: '7.haut-ral-4',
              },
              {
                slug: 'armTooTight',
                label: 'Manches trop serrées',
                simpleAlterationId: '7.haut-ela-2',
                complexAlterationId: '7.haut-ela-6',
              },
              {
                slug: 'shoulderTooTight',
                label: 'Épaules trop serrées',
                simpleAlterationId: '7.haut-ela-1',
                complexAlterationId: '7.haut-ela-5',
              },
            ],
          },
          {
            slug: 'damaged',
            label: 'Il est abîmé',
            question: 'À quel endroit est-il abîmé ?',
            locations: [
              {
                slug: 'button',
                label: 'Il manque un bouton',
                simpleAlterationId: '7.haut-div-10',
                complexAlterationId: '7.haut-div-25',
                multiple: true,
              },
              {
                slug: 'brokenZip',
                label: 'Le zip est bloqué',
                simpleAlterationId: '7.haut-div-5',
                complexAlterationId: '7.haut-div-20',
                multiple: true,
              },
              {
                slug: 'tornHoley',
                label: 'Il est déchiré / troué',
                simpleAlterationId: '7.haut-div-11',
                complexAlterationId: '7.haut-div-26',
                multiple: true,
              },
              {
                slug: 'reinforcement',
                label: 'Il nécessite un renfort',
                simpleAlterationId: '7.haut-div-15',
                complexAlterationId: '7.haut-div-30',
                multiple: true,
              },
            ],
          },
          {
            slug: 'talkRDV',
            label: 'En parler en RDV',
          },
        ],
      },
      {
        label: 'Broderie',
        sublabel: 'Décorer à l’aide d’un motif',
        slug: 'embroidery',
      },
      {
        label: 'Upcycling',
        sublabel: 'Transformer l’aspect initial',
        slug: 'transformation',
      },
      {
        label: 'Création d’accessoires',
        sublabel: 'Récupérer pour créer',
        slug: 'creation',
      },
    ],
  },
  {
    name: 'Jupe',
    slug: 'jupe',
    image: jupe,
    typesOfWork: [
      {
        label: 'Retouche',
        sublabel: 'Ajuster la taille, réparer',
        slug: 'alteration',
        problems: [
          {
            slug: 'tooLarge',
            label: 'Elle est trop grande',
            question: 'À quel endroit est-elle trop grande ?',
            locations: [
              {
                slug: 'tooLarge',
                label: 'Elle est trop large',
                simpleAlterationId: '7.jupe-cin-1',
                complexAlterationId: '7.jupe-cin-3',
              },
              {
                slug: 'tooLong',
                label: 'Elle est trop longue',
                simpleAlterationId: '7.jupe-rac-1',
                complexAlterationId: '7.jupe-rac-4',
              },
            ],
          },
          {
            slug: 'tooSmall',
            label: 'Elle est trop petite',
            question: 'À quel endroit est-elle trop petite ?',
            locations: [
              {
                slug: 'tooSmall',
                label: 'Elle est trop étroite',
                simpleAlterationId: '7.jupe-ela-1',
                complexAlterationId: '7.jupe-ela-3',
              },
              {
                slug: 'tooShort',
                label: 'Elle est trop courte',
                simpleAlterationId: '7.jupe-ral-1',
                complexAlterationId: '7.jupe-ral-3',
              },
            ],
          },
          {
            slug: 'damaged',
            label: 'Elle est abîmée',
            question: 'À quel endroit est-elle abîmée ?',
            locations: [
              {
                slug: 'button',
                label: 'Il manque un bouton',
                simpleAlterationId: '7.jupe-div-9',
                complexAlterationId: '7.jupe-div-23',
                multiple: true,
              },
              {
                slug: 'brokenZip',
                label: 'Le zip est bloqué',
                simpleAlterationId: '7.jupe-div-2',
                complexAlterationId: '7.jupe-div-16',
                multiple: true,
              },
              {
                slug: 'tornHoley',
                label: 'Elle est déchirée / trouée',
                simpleAlterationId: '7.jupe-div-10',
                complexAlterationId: '7.jupe-div-24',
                multiple: true,
              },
              {
                slug: 'reinforcement',
                label: 'Elle nécessite un renfort',
                simpleAlterationId: '7.jupe-div-14',
                complexAlterationId: '7.jupe-div-28',
                multiple: true,
              },
            ],
          },
          {
            slug: 'hem',
            label: 'Faire un ourlet',
            question: 'À quel endroit est-elle trop grande ?',
            locations: [
              {
                slug: 'hem',
                label: 'Sur la longueur',
                simpleAlterationId: '7.jupe-rac-1',
                complexAlterationId: '7.jupe-rac-4',
              },
            ],
          },
          {
            slug: 'talkRDV',
            label: 'En parler en RDV',
          },
        ],
      },
      {
        label: 'Broderie',
        sublabel: 'Décorer à l’aide d’un motif',
        slug: 'embroidery',
      },
      {
        label: 'Upcycling',
        sublabel: 'Transformer l’aspect initial',
        slug: 'transformation',
      },
      {
        label: 'Création d’accessoires',
        sublabel: 'Récupérer pour créer',
        slug: 'creation',
      },
    ],
  },
  {
    name: 'Pull & gilet',
    slug: 'pull',
    image: pull,
    typesOfWork: [
      {
        label: 'Retouche',
        sublabel: 'Ajuster la taille, réparer',
        slug: 'alteration',
        problems: [
          {
            slug: 'tooLarge',
            label: 'Il est trop grand',
            question: 'À quel endroit est-il trop grand ?',
            locations: [
              {
                slug: 'longArm',
                label: 'Manches trop longues',
                simpleAlterationId: '7.pull-rac-2',
                complexAlterationId: '7.pull-rac-5',
              },
              {
                slug: 'tooLong',
                label: 'Il est trop long',
                simpleAlterationId: '7.pull-rac-1',
                complexAlterationId: '7.pull-rac-4',
              },
              {
                slug: 'largeChest',
                label: 'Buste trop large',
                simpleAlterationId: '7.pull-cin-5',
                complexAlterationId: '7.pull-cin-10',
              },
              {
                slug: 'largeArm',
                label: 'Manches trop larges',
                simpleAlterationId: '7.pull-cin-3',
                complexAlterationId: '7.pull-cin-8',
              },
              {
                slug: 'largeShoulder',
                label: 'Épaules trop larges',
                simpleAlterationId: '7.pull-cin-2',
                complexAlterationId: '7.pull-cin-7',
              },
            ],

          },
          {
            slug: 'tooSmall',
            label: 'Il est trop petit',
            question: 'À quel endroit est-il trop petit ?',
            locations: [
              {
                slug: 'shortArm',
                label: 'Manches trop courtes',
                simpleAlterationId: '7.pull-ral-2',
                complexAlterationId: '7.pull-ral-4',
              },
              {
                slug: 'shortChest',
                label: 'Buste trop étroit',
                simpleAlterationId: '7.pull-ela-5',
                complexAlterationId: '7.pull-ela-10',
              },
              {
                slug: 'tooShort',
                label: 'Il est trop court',
                simpleAlterationId: '7.pull-ral-1',
                complexAlterationId: '7.pull-ral-4',
              },
              {
                slug: 'armTooTight',
                label: 'Manches trop serrées',
                simpleAlterationId: '7.pull-ela-3',
                complexAlterationId: '7.pull-ela-8',
              },
              {
                slug: 'shoulderTooTight',
                label: 'Épaules trop serrées',
                simpleAlterationId: '7.pull-ela-2',
                complexAlterationId: '7.pull-ela-7',
              },
            ],
          },
          {
            slug: 'damaged',
            label: 'Il est abîmé',
            question: 'À quel endroit est-il abîmé ?',
            locations: [
              {
                slug: 'button',
                label: 'Il manque un bouton',
                simpleAlterationId: '7.pull-div-10',
                complexAlterationId: '7.pull-div-25',
                multiple: true,
              },
              {
                slug: 'brokenZip',
                label: 'Le zip est bloqué',
                simpleAlterationId: '7.pull-div-5',
                complexAlterationId: '7.pull-div-20',
                multiple: true,
              },
              {
                slug: 'tornHoley',
                label: 'Il est déchiré / troué',
                simpleAlterationId: '7.pull-div-33',
                complexAlterationId: '7.pull-div-34',
                multiple: true,
              },
              {
                slug: 'reinforcement',
                label: 'Il nécessite un renfort',
                simpleAlterationId: '7.pull-div-15',
                complexAlterationId: '7.pull-div-30',
                multiple: true,
              },
            ],
          },
          {
            slug: 'talkRDV',
            label: 'En parler en RDV',
          },
        ],
      },
      {
        label: 'Broderie',
        sublabel: 'Décorer à l’aide d’un motif',
        slug: 'embroidery',
      },
      {
        label: 'Upcycling',
        sublabel: 'Transformer l’aspect initial',
        slug: 'transformation',
      },
      {
        label: 'Création d’accessoires',
        sublabel: 'Récupérer pour créer',
        slug: 'creation',
      },
    ],
  },
  {
    name: 'Lingerie',
    slug: 'ling',
    image: ling,
    typesOfWork: [
      {
        label: 'Retouche',
        sublabel: 'Ajuster la taille, réparer',
        slug: 'alteration',
        problems: [
          {
            slug: 'tooLarge',
            label: 'Il est trop grand',
            question: 'À quel endroit est-il trop grand ?',
            locations: [
              {
                slug: 'tooLargeSling',
                label: 'Au niveau des bretelles',
                simpleAlterationId: '7.ling-rac-1',
                complexAlterationId: '7.ling-rac-4',
              },
              {
                slug: 'chest',
                label: 'Au niveau de de la poitrine',
                simpleAlterationId: '7.ling-cin-1',
                complexAlterationId: '7.ling-cin-5',
              },
              {
                slug: 'tooLarge',
                label: 'Au niveau de la taille',
                simpleAlterationId: '7.ling-cin-4',
                complexAlterationId: '7.ling-cin-8',
              },
              {
                slug: 'tooLong',
                label: 'Il est trop long',
                simpleAlterationId: '7.ling-rac-3',
                complexAlterationId: '7.ling-rac-3',
              },
            ],
          },
          {
            slug: 'tooSmall',
            label: 'Il est trop petit',
            question: 'À quel endroit est-il trop petit ?',
            locations: [
              {
                slug: 'tooSmallSling',
                label: 'Au niveau des bretelles',
                simpleAlterationId: '7.ling-ral-1',
                complexAlterationId: '7.ling-ral-4',
              },
              {
                slug: 'chest',
                label: 'Au niveau de la poitrine',
                simpleAlterationId: '7.ling-ela-1',
                complexAlterationId: '7.ling-ela-3',
              },
              {
                slug: 'tooSmall',
                label: 'Au niveau de la taille',
                simpleAlterationId: '7.ling-ela-2',
                complexAlterationId: '7.ling-ela-4',
              },
              {
                slug: 'tooShort',
                label: 'Il est trop court',
                simpleAlterationId: '7.ling-ral-3',
                complexAlterationId: '7.ling-ral-3',
              },
            ],
          },
          {
            slug: 'damaged',
            label: 'Il est abîmé',
            question: 'À quel endroit est-il abîmé ?',
            locations: [
              {
                slug: 'damagedElastic',
                label: 'Changement de l’élastique',
                simpleAlterationId: '7.ling-div-8',
                complexAlterationId: '7.ling-div-21',
                multiple: true,
              },
              {
                slug: 'damagedWhale',
                label: 'Changement des baleines',
                simpleAlterationId: '7.ling-div-10',
                complexAlterationId: '7.ling-div-24',
                multiple: true,
              },
              {
                slug: 'damagedSling',
                label: 'Changement des bretelles',
                simpleAlterationId: '7.ling-div-3',
                complexAlterationId: '7.ling-div-16',
              },
              {
                slug: 'brokenZip',
                label: 'Le zip est bloqué',
                simpleAlterationId: '7.ling-div-1',
                complexAlterationId: '7.ling-div-14',
                multiple: true,
              },
              {
                slug: 'tornHoley',
                label: 'Il est déchiré / troué',
                simpleAlterationId: '7.ling-div-7',
                complexAlterationId: '7.ling-div-20',
                multiple: true,
              },
            ],
          },
          {
            slug: 'talkRDV',
            label: 'En parler en RDV',
          },
        ],
      },
      {
        label: 'Broderie',
        sublabel: 'Décorer à l’aide d’un motif',
        slug: 'embroidery',
      },
      {
        label: 'Upcycling',
        sublabel: 'Transformer l’aspect initial',
        slug: 'transformation',
      },
      {
        label: 'Création d’accessoires',
        sublabel: 'Récupérer pour créer',
        slug: 'creation',
      },
    ],
  },
  {
    name: 'Combinaison',
    slug: 'comb',
    image: comb,
    typesOfWork: [
      {
        label: 'Retouche',
        sublabel: 'Ajuster la taille, réparer',
        slug: 'alteration',
        problems: [
          {
            slug: 'tooLarge',
            label: 'Elle est trop grande',
            question: 'À quel endroit est-elle trop grande ?',
            locations: [
              {
                slug: 'longArm',
                label: 'Manches trop longues',
                simpleAlterationId: '7.comb-rac-1',
                complexAlterationId: '7.comb-rac-5',
              },
              {
                slug: 'largeArm',
                label: 'Manches trop larges',
                simpleAlterationId: '7.comb-cint-2',
                complexAlterationId: '7.comb-cint-10',
              },
              {
                slug: 'largeChest',
                label: 'Buste / Taille trop large',
                simpleAlterationId: '7.comb-cint-3',
                complexAlterationId: '7.comb-cint-11',
              },
              {
                slug: 'largeCrotch',
                label: 'À l’entrejambe',
                simpleAlterationId: '7.comb-cint-8',
                complexAlterationId: '7.comb-cint-16',
              },
              {
                slug: 'largeLeg',
                label: 'Jambes trop larges',
                simpleAlterationId: '7.comb-cint-6',
                complexAlterationId: '7.comb-cint-14',
              },
              {
                slug: 'longLeg',
                label: 'Jambes trop longues',
                simpleAlterationId: '7.comb-rac-3',
                complexAlterationId: '7.comb-rac-7',
              },
            ],
          },
          {
            slug: 'tooSmall',
            label: 'Elle est trop petite',
            question: 'À quel endroit est-elle trop petite ?',
            locations: [
              {
                slug: 'shortArm',
                label: 'Manches trop courtes',
                simpleAlterationId: '7.comb-ral-1',
                complexAlterationId: '7.comb-ral-4',
              },
              {
                slug: 'tightArm',
                label: 'Manches trop serrées',
                simpleAlterationId: '7.comb-ela-2',
                complexAlterationId: '7.comb-ela-10',
              },
              {
                slug: 'tightChest',
                label: 'Buste / Taille trop serrée',
                simpleAlterationId: '7.comb-ela-3',
                complexAlterationId: '7.comb-ela-11',
              },
              {
                slug: 'smallCrotch',
                label: 'À l’entrejambe',
                simpleAlterationId: '7.comb-ela-8',
                complexAlterationId: '7.comb-ela-16',
              },
              {
                slug: 'tightLeg',
                label: 'Jambes trop serrées',
                simpleAlterationId: '7.comb-ela-6',
                complexAlterationId: '7.comb-ela-14',
              },
              {
                slug: 'shortLeg',
                label: 'Jambes trop courtes',
                simpleAlterationId: '7.comb-ral-3',
                complexAlterationId: '7.comb-ral-6',
              },
            ],
          },
          {
            slug: 'damaged',
            label: 'Elle est abîmée',
            question: 'À quel endroit est-elle abîmée ?',
            locations: [
              {
                slug: 'button',
                label: 'Il manque un bouton',
                simpleAlterationId: '7.comb-div-13',
                complexAlterationId: '7.comb-div-31',
                multiple: true,
              },
              {
                slug: 'brokenZip',
                label: 'Le zip est bloqué',
                simpleAlterationId: '7.comb-div-6',
                complexAlterationId: '7.comb-div-25',
                multiple: true,
              },
              {
                slug: 'tornHoley',
                label: 'Elle est déchirée / trouée',
                simpleAlterationId: '7.comb-div-14',
                complexAlterationId: '7.comb-div-32',
                multiple: true,
              },
              {
                slug: 'pocket',
                label: 'Une poche est trouée',
                simpleAlterationId: '7.comb-div-9',
                complexAlterationId: '7.comb-div-27',
                multiple: true,
              },
            ],
          },
          {
            slug: 'hem',
            label: 'Faire un ourlet',
            question: 'À quel endroit est-elle trop grande ?',
            locations: [
              {
                slug: 'hem',
                label: 'Sur la longueur',
                simpleAlterationId: '7.comb-rac-3',
                complexAlterationId: '7.comb-rac-7',
              },
            ],
          },
          {
            slug: 'talkRDV',
            label: 'En parler en RDV',
          },
        ],
      },
      {
        label: 'Broderie',
        sublabel: 'Décorer à l’aide d’un motif',
        slug: 'embroidery',
      },
      {
        label: 'Upcycling',
        sublabel: 'Transformer l’aspect initial',
        slug: 'transformation',
      },
      {
        label: 'Création d’accessoires',
        sublabel: 'Récupérer pour créer',
        slug: 'creation',
      },
    ],
  },
  {
    name: 'Maillot de bain',
    slug: 'mail',
    image: mail,
    typesOfWork: [
      {
        label: 'Retouche',
        sublabel: 'Ajuster la taille, réparer',
        slug: 'alteration',
        problems: [
          {
            slug: 'tooLarge',
            label: 'Il est trop grand',
            question: 'À quel endroit est-il trop grand ?',
            locations: [
              {
                slug: 'tooLargeSling',
                label: 'Au niveau des bretelles',
                simpleAlterationId: '7.ling-rac-1',
                complexAlterationId: '7.ling-rac-4',
              },
              {
                slug: 'chest',
                label: 'Au niveau de de la poitrine',
                simpleAlterationId: '7.ling-cin-1',
                complexAlterationId: '7.ling-cin-5',
              },
              {
                slug: 'tooLarge',
                label: 'Au niveau de la taille',
                simpleAlterationId: '7.ling-cin-4',
                complexAlterationId: '7.ling-cin-8',
              },
              {
                slug: 'tooLong',
                label: 'Il est trop long',
                simpleAlterationId: '7.ling-rac-3',
                complexAlterationId: '7.ling-rac-3',
              },
            ],
          },
          {
            slug: 'tooSmall',
            label: 'Il est trop petit',
            question: 'À quel endroit est-il trop petit ?',
            locations: [
              {
                slug: 'tooSmallSling',
                label: 'Au niveau des bretelles',
                simpleAlterationId: '7.ling-ral-1',
                complexAlterationId: '7.ling-ral-4',
              },
              {
                slug: 'chest',
                label: 'Au niveau de la poitrine',
                simpleAlterationId: '7.ling-ela-1',
                complexAlterationId: '7.ling-ela-3',
              },
              {
                slug: 'tooSmall',
                label: 'Au niveau de la taille',
                simpleAlterationId: '7.ling-ela-2',
                complexAlterationId: '7.ling-ela-4',
              },
              {
                slug: 'tooShort',
                label: 'Il est trop court',
                simpleAlterationId: '7.ling-ral-3',
                complexAlterationId: '7.ling-ral-3',
              },
            ],
          },
          {
            slug: 'damaged',
            label: 'Il est abîmé',
            question: 'À quel endroit est-il abîmé ?',
            locations: [
              {
                slug: 'damagedElastic',
                label: 'Changement de l’élastique',
                simpleAlterationId: '7.ling-div-8',
                complexAlterationId: '7.ling-div-21',
                multiple: true,
              },
              {
                slug: 'damagedWhale',
                label: 'Changement des baleines',
                simpleAlterationId: '7.ling-div-10',
                complexAlterationId: '7.ling-div-24',
                multiple: true,
              },
              {
                slug: 'damagedSling',
                label: 'Changement des bretelles',
                simpleAlterationId: '7.ling-div-3',
                complexAlterationId: '7.ling-div-16',
              },
              {
                slug: 'brokenZip',
                label: 'Le zip est bloqué',
                simpleAlterationId: '7.ling-div-1',
                complexAlterationId: '7.ling-div-14',
                multiple: true,
              },
              {
                slug: 'tornHoley',
                label: 'Il est déchiré / troué',
                simpleAlterationId: '7.ling-div-7',
                complexAlterationId: '7.ling-div-20',
                multiple: true,
              },
            ],
          },
          {
            slug: 'talkRDV',
            label: 'En parler en RDV',
          },
        ],
      },
      {
        label: 'Broderie',
        sublabel: 'Décorer à l’aide d’un motif',
        slug: 'embroidery',
      },
      {
        label: 'Upcycling',
        sublabel: 'Transformer l’aspect initial',
        slug: 'transformation',
      },
      {
        label: 'Création d’accessoires',
        sublabel: 'Récupérer pour créer',
        slug: 'creation',
      },
    ],
  },
  {
    name: 'Costume',
    slug: 'cost',
    image: cost,
    pieces: {
      jacket: 'Veste de costume',
      pants: 'Pantalon de costume',
    },
    typesOfWork: [
      {
        label: 'Retouche',
        sublabel: 'Ajuster la taille, réparer',
        slug: 'alteration',
      },
      {
        label: 'Broderie',
        sublabel: 'Décorer à l’aide d’un motif',
        slug: 'embroidery',
      },
      {
        label: 'Upcycling',
        sublabel: 'Transformer l’aspect initial',
        slug: 'transformation',
      },
      {
        label: 'Création d’accessoires',
        sublabel: 'Récupérer pour créer',
        slug: 'creation',
      },
    ],
  },
  {
    name: 'Robe de mariée',
    slug: 'mari',
    image: mari,
    typesOfWork: [
      {
        label: 'Retouche',
        sublabel: 'Ajuster la taille, réparer',
        slug: 'alteration',
        problems: [
          {
            slug: 'tooLarge',
            label: 'Elle est trop grande',
            question: 'À quel endroit est-elle trop grande ?',
            locations: [
              {
                slug: 'tooLarge',
                label: 'Elle est trop large',
                simpleAlterationId: '7.mari-cin-5',
                complexAlterationId: '7.mari-cin-5',
              },
              {
                slug: 'tooLong',
                label: 'Elle est trop longue',
                simpleAlterationId: '7.mari-rac-1',
                complexAlterationId: '7.mari-rac-1',
              },
              {
                slug: 'longArm',
                label: 'Manches trop longues',
                simpleAlterationId: '7.mari-rac-4',
                complexAlterationId: '7.mari-rac-4',
              },
              {
                slug: 'largeArm',
                label: 'Manches trop larges',
                simpleAlterationId: '7.mari-cin-3',
                complexAlterationId: '7.mari-cin-3',
              },
              {
                slug: 'tooLargeSling',
                label: 'Au niveau des bretelles',
                simpleAlterationId: '7.mari-rac-6',
                complexAlterationId: '7.mari-rac-6',
              },
              {
                slug: 'largeShoulder',
                label: 'Épaules trop large',
                simpleAlterationId: '7.mari-cin-2',
                complexAlterationId: '7.mari-cin-2',
              },
              {
                slug: 'largeChest',
                label: 'Buste trop large',
                simpleAlterationId: '7.mari-cin-5',
                complexAlterationId: '7.mari-cin-5',
              },
            ],
          },
          {
            slug: 'tooSmall',
            label: 'Elle est trop petite',
            question: 'À quel endroit est-elle trop petite ?',
            locations: [
              {
                slug: 'tooShort',
                label: 'Elle est trop courte',
                simpleAlterationId: '7.mari-ral-1',
                complexAlterationId: '7.mari-ral-1',
              },
              {
                slug: 'shortArm',
                label: 'Manches trop courtes',
                simpleAlterationId: '7.mari-ral-4',
                complexAlterationId: '7.mari-ral-4',
              },
              {
                slug: 'armTooTight',
                label: 'Manches trop sérrées',
                simpleAlterationId: '7.mari-ela-3',
                complexAlterationId: '7.mari-ela-3',
              },
              {
                slug: 'tooSmallSling',
                label: 'Au niveau des bretelles',
                simpleAlterationId: '7.mari-ral-6',
                complexAlterationId: '7.mari-ral-6',
              },
              {
                slug: 'smallShoulder',
                label: 'Épaules trop étroite',
                simpleAlterationId: '7.mari-ela-2',
                complexAlterationId: '7.mari-ela-2',
              },
              {
                slug: 'smallChest',
                label: 'Buste trop étroit',
                simpleAlterationId: '7.mari-ela-5',
                complexAlterationId: '7.mari-ela-5',
              },
            ],
          },
          {
            slug: 'damaged',
            label: 'Elle est abîmée',
            question: 'À quel endroit est-elle abîmée ?',
            locations: [
              {
                slug: 'button',
                label: 'Il manque un bouton',
                simpleAlterationId: '7.mari-div-11',
                complexAlterationId: '7.mari-div-11',
                multiple: true,
              },
              {
                slug: 'brokenZip',
                label: 'Le zip est bloqué',
                simpleAlterationId: '7.mari-div-2',
                complexAlterationId: '7.mari-div-2',
                multiple: true,
              },
              {
                slug: 'tornHoley',
                label: 'Elle est déchirée / trouée',
                simpleAlterationId: '7.mari-div-13',
                complexAlterationId: '7.mari-div-13',
                multiple: true,
              },
            ],
          },
          {
            slug: 'hem',
            label: 'Faire un ourlet',
            question: 'À quel endroit est-elle trop grande ?',
            locations: [
              {
                slug: 'hem',
                label: 'Sur la longueur',
                simpleAlterationId: '7.mari-rac-1',
                complexAlterationId: '7.mari-rac-1',
              },
            ],
          },
          {
            slug: 'talkRDV',
            label: 'En parler en RDV',
          },
        ],
      },
      {
        label: 'Broderie',
        sublabel: 'Décorer à l’aide d’un motif',
        slug: 'embroidery',
      },
      {
        label: 'Upcycling',
        sublabel: 'Transformer l’aspect initial',
        slug: 'transformation',
      },
      {
        label: 'Création d’accessoires',
        sublabel: 'Récupérer pour créer',
        slug: 'creation',
      },
    ],
  },
  {
    name: 'Accessoire',
    slug: 'acce',
    image: acce,
    typesOfWork: [
      {
        label: 'Retouche',
        sublabel: 'Ajuster la taille, réparer',
        slug: 'alteration',
        problems: [
          {
            slug: 'damaged',
            label: 'Il est abîmé',
            question: 'À quel endroit est-il abîmé ?',
            locations: [
              {
                slug: 'damagedElastic',
                label: 'Il faut changer l’élastique',
                simpleAlterationId: '7.acce-div-5',
                complexAlterationId: '7.acce-div-13',
                multiple: true,
              },
              {
                slug: 'brokenZip',
                label: 'Le zip est bloqué',
                simpleAlterationId: '7.acce-div-1',
                complexAlterationId: '7.acce-div-9',
                multiple: true,
              },
              {
                slug: 'tornHoley',
                label: 'Il est déchiré / troué',
                simpleAlterationId: '7.acce-div-4',
                complexAlterationId: '7.acce-div-12',
                multiple: true,
              },
              {
                slug: 'button',
                label: 'Il manque un bouton',
                simpleAlterationId: '7.acce-div-3',
                complexAlterationId: '7.acce-div-11',
                multiple: true,
              },
            ],
          },
          {
            slug: 'adjustment_acce_multiple',
            label: 'Besoin d’ajustement',
            simpleAlterationId: '7.acce-div-4',
            complexAlterationId: '7.acce-div-12',
            multiple: true,
          },
        ],
      },
      {
        label: 'Broderie',
        sublabel: 'Décorer à l’aide d’un motif',
        slug: 'embroidery',
      },
      {
        label: 'Upcycling',
        sublabel: 'Transformer l’aspect initial',
        slug: 'transformation',
      },
      {
        label: 'Création d’accessoires',
        sublabel: 'Récupérer pour créer',
        slug: 'creation',
      },
    ],
  },
  {
    name: 'Rideau',
    slug: 'ride',
    image: mais,
    typesOfWork: [
      {
        label: 'Retouche',
        sublabel: 'Ajuster la taille, réparer',
        slug: 'alteration',
      },
      {
        label: 'Broderie',
        sublabel: 'Décorer à l’aide d’un motif',
        slug: 'embroidery',
      },
      {
        label: 'Upcycling',
        sublabel: 'Transformer l’aspect initial',
        slug: 'transformation',
      },
      {
        label: 'Création d’accessoires',
        sublabel: 'Récupérer pour créer',
        slug: 'creation',
      },
    ],
  },
  {
    name: 'Coussin',
    slug: 'cous',
    image: mais,
    typesOfWork: [
      {
        label: 'Retouche',
        sublabel: 'Ajuster la taille, réparer',
        slug: 'alteration',
      },
      {
        label: 'Broderie',
        sublabel: 'Décorer à l’aide d’un motif',
        slug: 'embroidery',
      },
      {
        label: 'Upcycling',
        sublabel: 'Transformer l’aspect initial',
        slug: 'transformation',
      },
      {
        label: 'Création d’accessoires',
        sublabel: 'Récupérer pour créer',
        slug: 'creation',
      },
    ],
  },
  {
    name: 'Linge de maison',
    slug: 'mais',
    image: mais,
    typesOfWork: [
      {
        label: 'Retouche',
        sublabel: 'Ajuster la taille, réparer',
        slug: 'alteration',
      },
      {
        label: 'Broderie',
        sublabel: 'Décorer à l’aide d’un motif',
        slug: 'embroidery',
      },
      {
        label: 'Upcycling',
        sublabel: 'Transformer l’aspect initial',
        slug: 'transformation',
      },
      {
        label: 'Création d’accessoires',
        sublabel: 'Récupérer pour créer',
        slug: 'creation',
      },
    ],
  },
];


const treeInverseWithoutSuit = {};

tree.forEach((cloth) => {
  if (!cloth.typesOfWork) return;
  cloth.typesOfWork.forEach((typeOfWork) => {
    if (!typeOfWork.problems) return;
    typeOfWork.problems.forEach((problem) => {
      if (!problem.locations) return;
      problem.locations.forEach((location) => {
        treeInverseWithoutSuit[`${cloth.slug}_${location.slug}`] = location;
      });
    });
  });
});

const treeInverse = { ...treeInverseWithoutSuit, ...treeAlterationSuitInverse };

const multipleQuestions = {
  pocket: 'Combien de poches sont trouées ?',
  button: 'Combien de boutons manquent-ils ?',
  brokenZip: 'Combien de zips sont bloqués ?',
  tornHoley: "Combien y'a-t-il de déchirures / trous ?",
  reinforcement: 'Combien de renforts sont nécéssaires ?',
  damagedElastic: "Combien d'élastiques doivent-être changés ?",
  damagedWhale: 'Combien de baleines doivent être changées ?',
  adjustment_acce: 'Combien de parties nécessitent un ajustement ?',
};

export { treeInverse, multipleQuestions, treeCreation, treeAlterationSuit };
export default tree;
