import styled from 'styled-components';

import { margins } from '../styledComponents';
import {
  colors,
  mobileThresholdPixels,
  FormInput as FormInputV2,
  Button as ButtonV3,
} from '../home/v3/styledComponents';
import { Subtitle } from './orderStyledComponents';

export const Container = styled.div`
  width: ${({ vertical }) => vertical ? 345 : 946}px;
  box-sizing: border-box;
  height: auto;
  margin: ${({ vertical }) => vertical ? '0px' : '80px auto 0px'};
  background-color: ${colors.white};
  padding: ${({ vertical }) => vertical ? '16px 30px 2px' : `${margins.l} ${margins.l} ${margins.s} ${margins.l}`};
  border-top: 8px solid ${colors.blueGrey};
  text-align: left;
  color: ${colors.navy};
  font-family: Roboto;

  @media (max-width: ${mobileThresholdPixels}) {
    width: calc(100vw - 40px);
    padding: 20px;
    margin: 45px auto;
  }
`;

export const StyledSubtitle = styled(Subtitle)`
  line-height: 24px;
  font-size: 18px;
`;

export const OrderContainer = styled.div`
  display: flex;
  flex-direction: ${({ vertical }) => vertical ? 'column' : 'row'};
  justify-content: space-between;

  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: column;
  }
`;

export const SubContainer = styled.div`
  width: ${({ vertical }) => vertical ? 'unset' : '369px'};

  @media (max-width: ${mobileThresholdPixels}) {
    width: unset;
  }
`;

export const TotalRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  margin: 20px 0px;
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom};`}
  ${({ grey }) => grey && `color: ${colors.lightGrey3}; `}

  @media (max-width: ${mobileThresholdPixels}) {
    margin: 10px 0px;
    font-size: 14px;
    line-height: 17px;
  }
`;

export const SubTotalRow = styled(TotalRow)`
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  margin-top: 0px;
  ${({ grey }) => grey && `color: ${colors.lightGrey3}; margin-top: -15px;`}

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 12px;
    line-height: 17px;
    ${({ grey }) => grey && `color: ${colors.lightGrey3}; margin-top: -5px;`}
  }
`;

export const Ul = styled.ul`
  ${({ vertical }) => vertical && 'padding-inline-start: 20px;'};

  @media (max-width: ${mobileThresholdPixels}) {
    padding-inline-start: 40px;
  }
`;

export const Li = styled.li`
  list-style-image : url(${props => props.icon});
  margin-right: 9px;
  margin-bottom: 10px;
  font-size: 15px;
  line-height: 20px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 14px;
    margin-right: 0px;
    margin-left: -20px;
  }
`;

export const Line = styled.div`
  border-width: 0.5px;
  border-style: solid;
  border-color: ${colors.lightGrey2};
  margin: ${props => props.marginTop || 20}px 0px ${props => props.noMarginBottom ? 0 : 20}px;
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom}px`};

  @media (max-width: ${mobileThresholdPixels}) {
    margin: ${props => props.marginTop || 10}px 0px 10px;
  }
`;

export const CalendarContainer = styled.div`
  margin-top: 20px;
`;

export const PromoCode = styled.div`
  font-size: 15px;
  line-height: 20px;
  font-weight: 500;
  color: ${colors.lightGrey3};
  cursor: pointer;
`;

export const PromoCodeForm = styled.div`
  display: flex;
  justify-content: ${props => props.vertical ? 'space-between' : 'space-around'};
  flex-wrap: wrap;
`;

export const Code = styled.div`
  font-weight: 300;
  font-size: 18px;
  line-height: 20px;
  color: ${colors.lightGrey4};
  margin-left: 5px;
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 14px;
    line-height: 17px;
  }
`;

export const Span = styled.span`
  color: ${colors.lightGrey4};
`;

export const FlexContainer = styled.div`
  display: flex;
`;

export const FormInput = styled(FormInputV2)`
  font-size: 14px;
  width: 142px;
  height: 38px;
  margin-top: 0px;
  border-color: ${colors.darkBlueGrey};
  padding-top: 0px;
  padding-bottom: 0px;
  outline: none;
  @media (max-width: ${mobileThresholdPixels}) {
    width: calc(60% - 15px);
  }
`;

export const Button = styled(ButtonV3)`
  width: 111px;
  height: 40px;
  font-size: 18px;
  min-width: unset;
  min-height: unset;
  padding: 3px 0px 0px;
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 13px;
    margin: 0px;
    width: 35%;
  }
`;

export const ErrorP = styled.p`
  font-family: Roboto;
  font-size: 15px;
  color: ${colors.red};
  width: 87%;
  margin-top: 15px;
  margin-bottom: 0px;
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 14px;
    width: 98%;
  }
`;

export const TransformationText = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 14px;
    line-height: 17px;
  }
`;

export const SwarovskiBanner = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 46px;
  margin-bottom: 22px;
  width: 100%;
`;

export const CheckBoxContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const SwarovskiTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-family: Roboto;
  font-size: 15px;
  line-height: 20px;
  margin-bottom: 15px;
  @media (max-width: ${mobileThresholdPixels}) {
    margin-top: -2px;
  }
`;

export const ItalicText = styled.div`
  margin-top: 10px;
  font-style: italic;
`;

export const BoldSpan = styled.span`
  font-weight: 500;
`;

export const SwarovskiImg = styled.img`
  width: 100%;
`;
