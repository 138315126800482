import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { formInputWidths } from './meetingInfosFormInputs';
import {
  colors, mobileThresholdPixels, FormInput as FormInputV2,
} from '../home/v3/styledComponents';
import errorLogo from '../../assets/error.png';
import verifiedLogo from '../../assets/verified.png';

const FormContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

const FormInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  color: ${colors.navy};
`;

const InputAndImgContainer = styled.div`
  display: flex;
`;

const FormInput = styled(FormInputV2)`
  margin: 0px ${({ error }) => error !== undefined ? '10' : '20'}px 20px 0px;
  border-radius: 2px;
  padding-top: 0px;
  padding-bottom: 0px;
  height: 38px;
  font-family: Roboto;
  font-size: 15px;
  outline: 0;
  border: solid 0.5px ${colors.navy};
  width: ${props => `${formInputWidths[props.size].desktop}`}px;
  color: ${colors.navy};
  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 100px hsl(1, 100%, 100%) inset;
    -webkit-text-fill-color: ${colors.navy};
  };
  @media (max-width: ${mobileThresholdPixels}) {
    ${props => (props.isMobileLarge || props.size !== 'small') && 'margin-right: 0px;'}
    width: ${props => props.isMobileLarge
    ? formInputWidths.large.mobile
    : formInputWidths[props.size].mobile}px;
    background-position-x: ${props => props.isMobileLarge
    ? formInputWidths.large.mobile - 8
    : formInputWidths[props.size].mobile - 8}px;
  }
`;

const MoreInfos = styled.p`
  ${props => props.noDisplay && 'display: none;'}
  font-family: Roboto;
  line-height: 20px;
  font-size: 14px;
  width: 373px;
  margin-top: -10px;
  margin-bottom: 20px;
  ${props => props.error && `
    color: ${colors.red};
    width: 145px;
    margin-top: ${props.errorOn2Lines ? '20%' : '28%'};
    margin-left: -6px;
  `}
  ${props => props.topInfo && `
    width: auto;
    margin: -21px 0px 14px;
  `}
  ${({ small }) => small && 'width: 100px;'}
  @media (max-width: ${mobileThresholdPixels}) {
    width: 258px;
    line-height: 17px;
    font-size: 12px;
    ${props => props.error && `
      width: ${props.errorOn2Lines ? '200px' : '135px'};
      margin-top: -15px;
      margin-left: 0px;
    `}
    ${props => props.topInfo && `
      width: auto;
      margin-top: -5px;
    `}
  }
`;

const I = styled.i`
  background-image: ${({ error }) => error ? `url(${errorLogo})` : `url(${verifiedLogo})`};
  background-size: contain;
  width: 10px;
  height: 10px;
  position: relative;
  left: -30px;
  top: 15px;
`;

const PhoneForm = ({ inputPhone, addingNewAddress, checkFormInputValue, setFormInputValue }) => (
  <FormContainer>
    <FormInputContainer key={inputPhone.name}>
      <InputAndImgContainer>
        <FormInput
          name={inputPhone.name}
          value={inputPhone.value ?? ''}
          type={inputPhone.type ?? 'text'}
          size={inputPhone.size}
          isMobileLarge={inputPhone.isMobileLarge}
          onBlur={() => checkFormInputValue(inputPhone.name, 'formInputs')}
          onChange={target => setFormInputValue(target, 'formInputs')}
          error={inputPhone.error}
          disabled={addingNewAddress ? 'disabled' : false}
        />{inputPhone.error !== undefined && <I error={inputPhone.error} />}
      </InputAndImgContainer>
      {inputPhone.more && inputPhone.more.isActive &&
        <MoreInfos>{inputPhone.more.value}</MoreInfos>
      }
    </FormInputContainer>
  </FormContainer>
);

PhoneForm.propTypes = {
  inputPhone: PropTypes.shape.isRequired,
  addingNewAddress: PropTypes.bool.isRequired,
  checkFormInputValue: PropTypes.func.isRequired,
  setFormInputValue: PropTypes.func.isRequired,
};

export default PhoneForm;
