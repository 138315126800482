const treeAlterationSuit = {
  question: 'À quels endroits seront les retouches ?',
  pieces: {
    jacket: {
      name: 'Veste de costume',
      label: 'La veste',
      question: 'Indiquez-nous le problème sur la veste :',
      problems: {
        tooLarge: {
          label: 'Elle est trop grande',
          question: 'À quel endroit est-elle trop grande ?',
          answers: [
            {
              slug: 'longArm',
              label: 'Manches trop longues',
              id: '7.mant-rac-2',
            },
            {
              slug: 'tooLong',
              label: 'Il est trop long',
              id: '7.mant-rac-1',
            },
            {
              slug: 'largeChest',
              label: 'Buste trop large',
              id: '7.mant-cin-4',
            },
            {
              slug: 'largeArm',
              label: 'Manches trop larges',
              id: '7.mant-cin-2',
            },
            {
              slug: 'largeShoulder',
              label: 'Épaules trop larges',
              id: '7.mant-cin-1',
            },
          ],
        },
        tooSmall: {
          label: 'Elle est trop petite',
          question: 'À quel endroit est-elle trop petite ?',
          answers: [
            {
              slug: 'shortArm',
              label: 'Manches trop courtes',
              id: '7.mant-ral-2',
            },
            {
              slug: 'shortChest',
              label: 'Buste trop étroit',
              id: '7.mant-ela-4',
            },
            {
              slug: 'tooShort',
              label: 'Il est trop court',
              id: '7.mant-ral-1',
            },
            {
              slug: 'armTooTight',
              label: 'Manches trop serrées',
              id: '7.mant-ela-2',
            },
            {
              slug: 'shoulderTooTight',
              label: 'Épaules trop serrées',
              id: '7.mant-ela-1',
            },
          ],
        },
        damaged: {
          label: 'Elle est abîmée',
          question: 'À quel endroit est-elle abîmée ?',
          answers: [
            {
              slug: 'entireLining',
              label: 'Sur toute la doublure',
              id: '7.mant-div-1',
            },
            {
              slug: 'liningPart',
              label: 'Sur une partie de la doublure',
              id: '7.mant-div-2',
            },
            {
              slug: 'pocket',
              label: 'Une poche est trouée',
              id: '7.mant-div-7',
              isMultiple: true,
            },
            {
              slug: 'button',
              label: 'Il manque un bouton',
              id: '7.mant-div-12',
              isMultiple: true,
            },
            {
              slug: 'brokenZip',
              label: 'Le zip est bloqué',
              id: '7.mant-div-5',
              isMultiple: true,
            },
            {
              slug: 'tornHoley',
              label: 'Elle est déchirée / trouée',
              id: '7.mant-div-13',
              isMultiple: true,
            },
            {
              slug: 'reinforcement',
              label: 'Elle nécessite un renfort',
              id: '7.mant-div-17',
              isMultiple: true,
            },
          ],
        },
        talkRDV: {
          label: 'En parler en RDV',
        },
      },
    },
    pants: {
      name: 'Pantalon de costume',
      label: 'Le pantalon',
      question: 'Indiquez-nous le problème sur le pantalon :',
      problems: {
        tooLarge: {
          label: 'Il est trop grand',
          question: 'À quel endroit est-il trop grand ?',
          answers: [
            {
              slug: 'tooLargeLeg',
              label: 'Sur les jambes',
              id: '7.pant-cint-6',
            },
            {
              slug: 'tooLargeCrotch',
              label: 'A l’entrejambe',
              id: '7.pant-cint-8',
            },
            {
              slug: 'tooLargeBelt',
              label: 'A la ceinture',
              id: '7.pant-cint-5',
            },
            {
              slug: 'tooLargeLength',
              label: 'Sur la longueur',
              id: '7.pant-rac-1',
              isNotSuit: true,
            },
          ],
        },
        tooSmall: {
          label: 'Il est trop petit',
          question: 'À quel endroit est-il trop petit ?',
          answers: [
            {
              slug: 'tooSmallLeg',
              label: 'Sur les jambes',
              id: '7.pant-ela-6',
            },
            {
              slug: 'tooSmallCrotch',
              label: 'A l’entrejambe',
              id: '7.pant-ela-8',
            },
            {
              slug: 'tooSmallBelt',
              label: 'A la ceinture',
              id: '7.pant-ela-5',
            },
            {
              slug: 'tooSmallLength',
              label: 'Sur la longueur',
              id: '7.pant-ral-2',
            },
          ],
        },
        damaged: {
          label: 'Il est abîmé',
          question: 'À quel endroit est-il abîmé ?',
          answers: [
            {
              slug: 'pocket',
              label: 'Une poche est trouée',
              id: '7.pant-div-4',
              isMultiple: true,
              isNotSuit: true,
            },
            {
              slug: 'button',
              label: 'Il manque un bouton',
              id: '7.pant-div-6',
              isMultiple: true,
              isNotSuit: true,
            },
            {
              slug: 'brokenZip',
              label: 'Le zip est bloqué',
              id: '7.pant-div-1',
              isMultiple: true,
              isNotSuit: true,
            },
            {
              slug: 'tornHoley',
              label: 'Il est déchiré / troué',
              id: '7.pant-div-7',
              isMultiple: true,
              isNotSuit: true,
            },
          ],
        },
        hem: {
          label: 'Faire un ourlet',
          question: 'À quel endroit est-il trop grand ?',
          answers: [
            {
              slug: 'hem',
              label: 'Sur la longueur',
              id: '7.pant-rac-1',
              isNotSuit: true,
            },
          ],
        },
        talkRDV: {
          label: 'En parler en RDV',
        },
      },
    },
  },
};

const treeAlterationSuitInverse = {};
Object.entries(treeAlterationSuit.pieces).forEach(([pieceSlug, piece]) => {
  Object.entries(piece.problems).forEach(([problemSlug, problem]) => {
    if (!problem.answers) return;
    problem.answers.forEach((answer) => {
      treeAlterationSuitInverse[`cost_${pieceSlug}_${answer.slug}`] = { ...answer, piece: pieceSlug, problem: problemSlug };
    });
  });
});

export { treeAlterationSuitInverse };

export default treeAlterationSuit;
