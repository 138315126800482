import React from 'react';
import styled from 'styled-components';
import { mobileThresholdPixels, StepContainer } from '../components/home/v3/styledComponents';
import bhv from '../assets/platform/bhv.png';

const Container = styled(StepContainer)`
  margin: 0px auto;
  padding: 0px;
  max-width: 100%;
  background-color: white;

  @media (max-width: ${mobileThresholdPixels}) {
    margin: 0px;
    padding: 0px;
  }
`;

const Img = styled.img`
  max-width: 100%;
`;

const PlatformLogo = () => (
  <Container>
    {process.env.GATSBY_BRAND && (<Img src={bhv} alt="logo BHV et Tilli" />)}
  </Container>
);

export default PlatformLogo;
