/* eslint-disable react/jsx-indent */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';

import {
  colors,
  mobileThresholdPixels,
} from '../home/v3/styledComponents';
import { pricingInverse } from '../../services/pricing';
import { upcyclingPricingInverse } from '../../core/pricings/upcyclingPricing';

import iconNeedleThread from '../../assets/order/iconNeedleThread.svg';
import iconFabric from '../../assets/order/iconFabric.svg';
import iconEdit from '../../assets/order/iconEdit.svg';
import iconGarbage from '../../assets/order/iconGarbage.svg';
import iconWarning from '../../assets/order/warning.jpg';
import { getFabricName } from './fabrics';
import { getFabricNameOfAccessories } from './fabricsAccessories';
import infoLogo from '../../assets/information.png';

const TotalRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  margin: 20px 0px;
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom};`}
  ${({ grey }) => grey && `color: ${colors.lightGrey3}; `}

  @media (max-width: ${mobileThresholdPixels}) {
    margin: 10px 0px;
    font-size: 14px;
    line-height: 17px;
  }
`;

const AlterationsRow = styled(TotalRow)`
  justify-content: flex-start;
  align-items: start;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  margin: 2.5px 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 12px;
  }
`;

const Icon = styled.img`
  padding: 4px 6px 4px 0px;
`;

const IconEdit = styled.img`
  margin-left: 5px;
  cursor: pointer;
`;

const getTypeOfWorkLabel = (selectedTypeOfWork) => {
  let action = '';
  if (selectedTypeOfWork === 'embroidery') {
    action = ' à broder';
  } else if (selectedTypeOfWork === 'alteration') {
    action = ' à retoucher';
  } else if (selectedTypeOfWork === 'transformation') {
    action = ' à transformer';
  }
  return action;
};

const FlexContainer = styled.div`
  display: flex;
`;

const Button = styled.div`
  color: ${colors.lightGrey3};
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  margin-top: 10px;
  @media (max-width: ${mobileThresholdPixels}) {
    margin-top: 5px;
  }
`;

const StyledReactTooltip = styled(ReactTooltip)`
  opacity: 1 !important;
  background-color: ${colors.navy} !important;
  width: auto !important;
  height: auto !important;
`;

const TooltipText = styled.p`
  font-family: Roboto;
  font-size: 13px;
  color: ${colors.white};
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 12px;
  }
`;

const Img = styled.img`
  margin-bottom: 8px;
  margin-left: 8px;
  height: 10px;
  @media (max-width: ${mobileThresholdPixels}) {
    margin-bottom: 4px;
    margin-left: 4px;
  }
`;

const renderTooltip = () => (
  <div>
    <StyledReactTooltip
      place="top"
      type="none"
      effect="float"
      id="undefinedPrice"
    >
      <TooltipText>Estimation en RDV</TooltipText>
    </StyledReactTooltip>
    <Img
      src={infoLogo}
      alt="Pourquoi le prix est indéfini ?"
      data-tip
      data-for="undefinedPrice"
    />
  </div>
);

const renderPriceRangeTooltip = priceRange => (
  <div>
    <StyledReactTooltip place="top" type="none" effect="float" id="priceRange">
      <TooltipText>
        Les prix peuvent varier à +/- {Math.round(priceRange / 2)}€ pour ce type de
        transformation.
      </TooltipText>
    </StyledReactTooltip>
    <Img
      src={iconWarning}
      alt={`Les prix peuvent varier à +/- ${Math.round(priceRange /
        2)}€ pour ce type de transformation.`}
      data-tip
      data-for="priceRange"
    />
  </div>
);

const renderPieceTotal = (cloth, priceRange) => {
  if (cloth.total === 0) {
    return <FlexContainer>?{renderTooltip()}</FlexContainer>;
  } else if (cloth.typeOfWork !== 'transformation' && cloth.hasTalkRDV) {
    return (
      <FlexContainer>
        {Math.round(cloth.total)} € + ?
        {renderTooltip()}
      </FlexContainer>
    );
  } else if (priceRange > 0) {
    return (
      <FlexContainer>
        {Math.round(cloth.total)} € {renderPriceRangeTooltip(priceRange)}
      </FlexContainer>
    );
  }
  return <span>{Math.round(cloth.total)} €</span>;
};

const OrderSummaryCloth = ({
  cloth,
  canEdit,
  clothes,
  deleteCloth,
  duplicateCloth,
  editingClothIndex,
  isRDV3Clicks,
  priceRange,
  setEditingClothIndex,
}) => (
  <React.Fragment>
    <TotalRow marginBottom={'10px'}>
      <span>
        {cloth.typeOfWork !== 'creation'
          ? `${cloth.name} ${getTypeOfWorkLabel(cloth.typeOfWork)} `
          : "Création d'accessoires"}

        {(canEdit ||
          (editingClothIndex === null && cloth.index !== clothes.length - 1) ||
          (editingClothIndex !== null &&
            cloth.index !== editingClothIndex)) && (
          <React.Fragment>
            <IconEdit
              onClick={() => setEditingClothIndex(cloth.index)}
              src={iconEdit}
              alt="Éditer"
            />
            {!isRDV3Clicks && (
              <IconEdit
                onClick={() => deleteCloth(cloth.index, cloth.suitPiece)}
                src={iconGarbage}
                alt="Supprimer"
              />
            )}
          </React.Fragment>
        )}
      </span>

      {renderPieceTotal(cloth, priceRange)}
    </TotalRow>

    {cloth.items.map((item) => {
      if (!isRDV3Clicks) {
        let pricingInverseName = pricingInverse[item.id].name.replace(
          ' (x1)',
          '',
        );
        if (item.quantity > 1) {
          pricingInverseName = `${pricingInverseName} (x${item.quantity})`;
        }
        return (
          <AlterationsRow key={item.id}>
            <Icon src={iconNeedleThread} alt="Retouche" />
            <span>{pricingInverseName}</span>
          </AlterationsRow>
        );
      }
      return (
        <AlterationsRow key={item.label}>
          <Icon src={iconNeedleThread} alt="Retouche" />
          <span>{item.label}</span>
        </AlterationsRow>
      );
    })}

    {cloth.upcyclingItems.map((item) => {
      let pricingInverseName = upcyclingPricingInverse[item.id].name.replace(
        ' (x1)',
        '',
      );
      if (item.quantity && item.quantity > 1) {
        pricingInverseName = `${pricingInverseName} (x${item.quantity})`;
      }
      return (
        <AlterationsRow key={item.id}>
          <Icon src={iconNeedleThread} alt="Retouche" />
          <span>{pricingInverseName}</span>
        </AlterationsRow>
      );
    })}

    {cloth.suitAlterations &&
      cloth.suitAlterations.map(suitAlteration => (
        <AlterationsRow key={suitAlteration.label}>
          <Icon src={iconNeedleThread} alt="Retouche" />
          <span>{suitAlteration.label}</span>
        </AlterationsRow>
      ))}

    {cloth.fabrics
      .filter(fabric => getFabricName(fabric))
      .map(fabric => (
        <AlterationsRow key={fabric}>
          <Icon src={iconFabric} alt="Matière" />
          <span>{getFabricName(fabric)}</span>
        </AlterationsRow>
      ))}

    {cloth.name === 'Robe de mariée' && (
      <AlterationsRow>
        <Icon src={iconFabric} alt="Matière" />
        <span>Dentelle et soie / mousseline</span>
      </AlterationsRow>
    )}

    {cloth.selectedFabricOfAccessoriesCreation &&
      cloth.selectedFabricOfAccessoriesCreation.length >= 1 && (
      <AlterationsRow>
        <Icon src={iconFabric} alt="Matière" />
        <span>
          {`Tissu(s) :
                      ${cloth.selectedFabricOfAccessoriesCreation.map(
        item => ` ${getFabricNameOfAccessories(item)}`,
      )}`}
        </span>
      </AlterationsRow>
    )}
    <Button
      onClick={() =>
        duplicateCloth(
          cloth.index,
          cloth.isCurrent && clothes[clothes.length - 1],
          cloth.suitPiece,
        )
      }
    >
      Dupliquer
    </Button>
  </React.Fragment>
);

OrderSummaryCloth.propTypes = {
  clothes: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          slug: PropTypes.string,
        }),
      ),
      typeOfWork: PropTypes.string,
      total: PropTypes.number,
      fabrics: PropTypes.arrayOf(PropTypes.string),
    }),
  ),
  cloth: PropTypes.shape({}).isRequired,
  canEdit: PropTypes.bool,
  deleteCloth: PropTypes.func.isRequired,
  duplicateCloth: PropTypes.func.isRequired,
  editingClothIndex: PropTypes.number,
  setEditingClothIndex: PropTypes.func,
  priceRange: PropTypes.number,
  isRDV3Clicks: PropTypes.bool.isRequired,
};

OrderSummaryCloth.defaultProps = {
  deleteCloth() {},
  duplicateCloth() {},
};

export default OrderSummaryCloth;
