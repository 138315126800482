export const inputs = {
  firstname: { value: '', name: 'firstname', label: 'Prénom', isRequired: true, size: 'small', isMobileLarge: true },
  lastname: { value: '', name: 'lastname', label: 'Nom', isRequired: true, size: 'medium', isMobileLarge: true },
  email: {
    value: '',
    name: 'email',
    label: 'Adresse mail',
    isRequired: true,
    size: 'large',
    isMobileLarge: true,
    more: {
      isActive: false,
    },
  },
  errorEmail: { name: 'errorEmail', isActive: false, label: 'Adresse non valide' },
  street: {
    value: '',
    name: 'street',
    label: 'Adresse (voie)',
    isRequired: true,
    size: 'large',
    isMobileLarge: true,
  },
  comment: {
    value: '',
    name: 'comment',
    label: "Complément d'adresse",
    isRequired: false,
    size: 'large',
    isMobileLarge: true,
    more: {
      // eslint-disable-next-line max-len
      value: 'Indiquez le bâtiment, l’étage, le code et toutes les indications qui pourront faciliter l’accès à notre couturier.',
      isActive: true,
    },
  },
  zipcode: {
    value: '',
    name: 'zipcode',
    label: 'Code postal',
    isRequired: true,
    size: 'small',
    isMobileLarge: false,
  },
  locality: { value: '', name: 'locality', label: 'Ville', isRequired: true, size: 'medium', isMobileLarge: false },
  errorZipcode: {
    name: 'errorZipcode',
    withDefaultProps: true,
    isActive: false,
    label: '',
  },
  phone: {
    value: '',
    name: 'phone',
    label: 'Numéro de téléphone',
    isRequired: true,
    size: 'small',
    isMobileLarge: true,
    more: {
      value: 'Indiquez votre numéro de téléphone, notre couturier en aura besoin pour vous contacter.',
      isActive: true,
    },
  },
  errorPhone: {
    name: 'errorPhone',
    isActive: false,
    label: 'Numéro de téléphone non valide',
  },
};

export const inputsAlreadyCustomer = {
  errorConnection: { name: 'errorConnection', isActive: false, label: 'Adresse mail ou/et mot de passe incorrect' },
  email: { value: '', name: 'email', label: 'Adresse mail', isRequired: true, size: 'large', isMobileLarge: true },
  errorEmail: { name: 'errorEmail', isActive: false, label: 'Adresse non valide' },
  password: {
    type: 'password',
    value: '',
    name: 'password',
    label: 'Mot de passe',
    isRequired: true,
    size: 'medium',
    isMobileLarge: true,
  },
};

export const formInputWidths = {
  small: { desktop: 143, mobile: 87 },
  medium: { desktop: 193, mobile: 124 },
  large: { desktop: 373, mobile: 258 },
};
