import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';

import withOrderContext from '../../withOrderContext';
import tree, { treeInverse, treeAlterationSuit } from './tree';
import { pricingInverse, metadata } from '../../services/pricing';
import { getTotalPaid, getTotalClothes, getMinOrderAmountFee, getClothTotal }
  from '../../core/services/orderPrice';
import { formatClothOrPiece } from '../../services/orderFormatting';
import callApi from '../../services/api';
import routesMap from '../../Routes';
import { pushToLayer } from '../../services/googleTagManager';
import OrderSummary from './OrderSummary';
import prefilledClothes from './prefilledClothes';
import { upcyclingPricingInverse } from '../../core/pricings/upcyclingPricing';


const getClothesWithSuitPiecesSplitted = (clothes) => {
  const clothesWithSuitPiecesSplitted = [];
  clothes.forEach((cloth, index) => {
    if (cloth.selectedCloth === 'cost' && cloth.selectedTypeOfWork === 'alteration') {
      cloth.suitPieces.forEach((suitPiece) => {
        const problems = {};
        cloth.suitProblems.filter(suitProblem => suitProblem.includes(`${suitPiece}_`)).forEach((suitProblem) => {
          const suitProblemSlug = suitProblem.replace(`${suitPiece}_`, '');
          problems[suitProblemSlug] = [];
          const suitItems = treeAlterationSuit.pieces[suitPiece].problems[suitProblemSlug]?.answers?.length === 1
            ? treeAlterationSuit.pieces[suitPiece].problems[suitProblemSlug].answers
            : cloth.suitItems.filter(({ piece, problem }) => piece === suitPiece && problem === suitProblemSlug);
          suitItems.forEach(({ isMultiple, slug, quantity }) => {
            const suitItemSlug = `${suitPiece}_${slug}`;
            problems[suitProblemSlug].push(suitItemSlug);
            if (isMultiple) problems[`${suitItemSlug}_multiple`] = [quantity];
          });
        });
        clothesWithSuitPiecesSplitted.push({
          ...cloth,
          index,
          selectedProblems: problems,
          selectedLining: cloth.suitPieceLinings[suitPiece] || '',
          suitPiece,
        });
      });
    } else {
      clothesWithSuitPiecesSplitted.push({ ...cloth, index });
    }
  });
  return clothesWithSuitPiecesSplitted;
};

class OrderSummaryContainer extends React.Component {
  constructor() {
    super();
    this.state = {
      promoCode: {
        code: '',
      },
      hasPromoCode: false,
      errorPromoCode: false,
      isPromoCodeDisplayed: true,
      useSwarovskiPromoCode: false,
    };
    this.setHasPromoCode = this.setHasPromoCode.bind(this);
    this.checkPromoCode = this.checkPromoCode.bind(this);
    this.setEditingClothIndex = this.setEditingClothIndex.bind(this);
    this.setPromoCode = this.setPromoCode.bind(this);
    this.toggleSwarovskiPromoCode = this.toggleSwarovskiPromoCode.bind(this);
  }

  componentDidMount() {
    const { prefilledStep, orderContext: { promoCode, addOrEditCloth } } = this.props;
    if (promoCode?.code) {
      this.setHasPromoCode();
      this.checkPromoCode(promoCode.code);
      if (promoCode.code === 'CLACDESDOIGTS_17052019') this.setIsPromoCodeDisplayed(false);
      if (promoCode.code === 'SPARKLING') this.toggleSwarovskiPromoCode();
    }
    if (prefilledStep?.prefilledCloth) {
      addOrEditCloth(prefilledClothes[prefilledStep.prefilledCloth]);
      this.checkPromoCode('TOUT_SE_TRANSFORME');
    }
  }

  setIsPromoCodeDisplayed(isPromoCodeDisplayed) {
    this.setState({ isPromoCodeDisplayed });
  }

  setHasPromoCode() {
    this.setState({ hasPromoCode: true });
  }

  setEditingClothIndex(index) {
    const { saveCloth, orderContext: { setEditingClothIndex, rdv3ClicksOrder }, setEditingCloth } = this.props;
    if (rdv3ClicksOrder && rdv3ClicksOrder.knowHow && rdv3ClicksOrder.knowHow.length !== 0) {
      navigate(routesMap.Step1.url);
    } else {
      saveCloth();
      setEditingClothIndex(index, setEditingCloth);
      navigate(routesMap.Step1.url);
    }
  }

  setPromoCode(code) {
    let promoCode = { ...this.state.promoCode };
    if (typeof code === 'string') {
      promoCode.code = code;
    } else {
      promoCode = code;
    }
    this.setState({ promoCode });
  }

  checkPromoCode(promoCode) {
    const { orderContext: { setPromoCode } } = this.props;
    callApi(`public/checkPromoCode/${promoCode}`)
      .then((res) => {
        pushToLayer({
          event: 'Order Funnel - Promo Code Added',
          promo_code: promoCode,
        });
        setPromoCode(res.promoCode);
        this.setPromoCode(res.promoCode);
        this.setState({ errorPromoCode: false });
      })
      .catch(() => {
        this.setState({
          promoCode: { code: '' },
          errorPromoCode: true,
        });
      });
  }

  toggleSwarovskiPromoCode() {
    const { useSwarovskiPromoCode } = this.state;
    const { orderContext: { unsetPromoCode } } = this.props;
    if (!useSwarovskiPromoCode) {
      this.checkPromoCode('SPARKLING');
    } else {
      this.setPromoCode({
        promoCode: {
          code: '',
        },
      });
      unsetPromoCode();
    }
    this.setState({
      useSwarovskiPromoCode: !useSwarovskiPromoCode,
      hasPromoCode: false,
    });
  }

  render() {
    const {
      clothes, selectedDates, vertical,
      orderContext: {
        discounts, promoCode, deleteCloth, deliveryFee, rdv3ClicksOrder, customer = {}, trackTotalPaid, duplicateCloth,
      },
      canEdit,
      onTotalPaid,
      hasZipcode,
      saveCloth,
    } = this.props;
    const { promoCode: promoCodeState } = this.state;

    const clothesWithSuitPiecesSplitted = getClothesWithSuitPiecesSplitted(clothes);
    const { discountVouchers } = customer;
    const { hasPromoCode, errorPromoCode, isPromoCodeDisplayed, useSwarovskiPromoCode } = this.state;
    const { minOrderAmount, version: pricingVersion } = metadata;
    const brand = !!(promoCode && promoCode.brand);
    const order = {
      deliveryFee,
      minOrderAmount,
      pricingVersion,
      clothes: clothesWithSuitPiecesSplitted
        .map(cloth => ({ ...formatClothOrPiece(tree, treeInverse, pricingInverse, upcyclingPricingInverse, cloth, brand), isCurrent: cloth.isCurrent })),
      discounts,
    };

    let formatedClothes = [];
    const isRDV3Clicks = rdv3ClicksOrder && rdv3ClicksOrder.knowHow && rdv3ClicksOrder.knowHow.length !== 0;
    if (!isRDV3Clicks) {
      formatedClothes = order.clothes.map(cloth => ({
        index: cloth.index,
        name: cloth.name,
        items: cloth.items,
        upcyclingItems: cloth.upcyclingItems || [],
        typeOfWork: cloth.typesOfWork[0],
        total: getClothTotal(cloth),
        fabrics: [cloth.fabric],
        selectedFabricOfAccessoriesCreation: cloth.selectedFabricOfAccessoriesCreation,
        isCurrent: cloth.isCurrent,
        suitPiece: cloth.suitPiece,
        hasTalkRDV: cloth.hasTalkRDV,
      }));
    } else {
      formatedClothes = [{
        index: 0,
        name: 'Commande rapide',
        items: rdv3ClicksOrder.knowHow,
        upcyclingItems: [],
        typeOfWork: '',
        total: 0,
        fabrics: rdv3ClicksOrder.fabrics,
      }];
    }

    const totalPaid = Math.round(getTotalPaid(order));
    const totalClothes = Math.round(getTotalClothes(order));
    const minOrderAmountFee = getMinOrderAmountFee(order);
    const hasDefinedPrice = formatedClothes.reduce((acc, cloth) => acc || cloth.total !== 0, false);

    return (
      <OrderSummary
        deliveryFee={deliveryFee}
        rawClothes={clothes}
        totalPaid={totalPaid}
        totalClothes={totalClothes}
        minOrderAmountFee={minOrderAmountFee}
        selectedDates={selectedDates}
        vertical={vertical}
        clothes={formatedClothes}
        hasPromoCode={hasPromoCode}
        errorPromoCode={errorPromoCode}
        setHasPromoCode={this.setHasPromoCode}
        checkPromoCode={this.checkPromoCode}
        promoCode={promoCodeState}
        setPromoCode={this.setPromoCode}
        canEdit={canEdit}
        setEditingClothIndex={this.setEditingClothIndex}
        deleteCloth={deleteCloth}
        hasDefinedPrice={hasDefinedPrice}
        discountVouchersValue={discountVouchers && discountVouchers.length > 0
          ? discountVouchers
            .filter(discountVoucher => discountVoucher.unit === 'EUROS')
            .filter(discountVoucher => !discountVoucher.usedByOrderId)
            .map(discountVoucher => discountVoucher.value)
            .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
          : 0}
        isRDV3Clicks={isRDV3Clicks}
        hasZipcode={hasZipcode}
        isPromoCodeDisplayed={isPromoCodeDisplayed}
        useSwarovskiPromoCode={useSwarovskiPromoCode}
        toggleSwarovskiPromoCode={() => this.toggleSwarovskiPromoCode()}
        trackTotalPaid={trackTotalPaid}
        onTotalPaid={onTotalPaid}
        saveCloth={saveCloth}
        duplicateCloth={duplicateCloth}
      />
    );
  }
}

OrderSummaryContainer.propTypes = {
  clothes: PropTypes.arrayOf(PropTypes.shape({
    selectedCloth: PropTypes.string.isRequired,
    selectedLining: PropTypes.string.isRequired,
    selectedProblems: PropTypes.shape({}),
    selectedFabric: PropTypes.string.isRequired,
    selectedTypeOfWork: PropTypes.string.isRequired,
  })),
  selectedDates: PropTypes.shape({}),
  vertical: PropTypes.bool,
  orderContext: PropTypes.shape({
    discounts: PropTypes.arrayOf(PropTypes.shape({})),
    promoCode: PropTypes.shape({}),
    addOrEditCloth: PropTypes.func.isRequired,
    setPromoCode: PropTypes.func.isRequired,
    deliveryFee: PropTypes.number.isRequired,
    rdv3ClicksOrder: PropTypes.shape({
      knowHow: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
      fabrics: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,
    customer: PropTypes.shape({
      discountVouchers: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    unsetPromoCode: PropTypes.func,
  }).isRequired,
  canEdit: PropTypes.bool,
  saveCloth: PropTypes.func,
  setEditingCloth: PropTypes.func,
  onTotalPaid: PropTypes.func,
  hasZipcode: PropTypes.bool,
  prefilledStep: PropTypes.shape({}),
};

OrderSummaryContainer.defaultProps = {
  clothes: [],
  selectedProblems: {},
  selectedDates: null,
  vertical: false,
  selectedTypeOfWork: '',
  canEdit: false,
  saveCloth: () => {},
  setEditingCloth: () => {},
  onTotalPaid: () => {},
  hasZipcode: false,
  prefilledStep: undefined,
};

export default withOrderContext(OrderSummaryContainer);
