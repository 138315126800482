import getInitialState from '../step1InitialState';
import { treeCreation } from './tree';

const treeCreaBag = treeCreation.find(({ slug }) => slug === 'crea_sac');
const treeCreaCushion = treeCreation.find(({ slug }) => slug === 'crea_hous');
const treeCreaClutch = treeCreation.find(({ slug }) => slug === 'crea_poch');

const prefilledClothes = {
  balzacBag: {
    ...getInitialState(),
    selectedAccessoryCreation: 'bag',
    selectedBagClosing: treeCreaBag
      .questions.find(({ slug }) => slug === 'closing')
      .answers.find(({ slug }) => slug === 'zipClosing'),
    selectedBagHandle: treeCreaBag
      .questions.find(({ slug }) => slug === 'handle')
      .answers.find(({ slug }) => slug === 'chainHandle'),
    selectedBagShape: treeCreaBag
      .questions.find(({ slug }) => slug === 'shape')
      .answers.find(({ slug }) => slug === 'COMPLEX'),
    selectedBagSize: treeCreaBag
      .questions.find(({ slug }) => slug === 'size')
      .answers.find(({ slug }) => slug === 'SMALL'),
    selectedCategory: 'clothes',
    selectedCloth: 'acce',
    selectedCreationNumberOfPieces: 1,
    selectedFabric: 'other',
    selectedTypeOfWork: 'creation',
    otherItems: ['7.crea_sac-supp-28', '7.crea_sac-supp-37', '7.crea_sac-supp-40'],
  },
  balzacCushion: {
    ...getInitialState(),
    selectedAccessoryCreation: 'cou2',
    selectedBagClosing: treeCreaCushion
      .questions.find(({ slug }) => slug === 'closing')
      .answers.find(({ slug }) => slug === 'invisibleZip'),
    selectedBagHandle: {},
    selectedBagShape: treeCreaCushion
      .questions.find(({ slug }) => slug === 'shape')
      .answers.find(({ slug }) => slug === 'SIMPLE'),
    selectedBagSize: treeCreaCushion
      .questions.find(({ slug }) => slug === 'size')
      .answers.find(({ slug }) => slug === 'BIG'),
    selectedCategory: 'clothes',
    selectedCloth: 'acce',
    selectedCreationNumberOfPieces: 1,
    selectedFabric: 'other',
    selectedTypeOfWork: 'creation',
  },
  balzacClutch: {
    ...getInitialState(),
    selectedAccessoryCreation: 'clutchBag',
    selectedBagClosing: treeCreaClutch
      .questions.find(({ slug }) => slug === 'closing')
      .answers.find(({ slug }) => slug === 'pressStudClosing'),
    selectedBagHandle: treeCreaClutch
      .questions.find(({ slug }) => slug === 'handle')
      .answers.find(({ slug }) => slug === 'noHandle'),
    selectedBagShape: treeCreaClutch
      .questions.find(({ slug }) => slug === 'shape')
      .answers.find(({ slug }) => slug === 'SIMPLE'),
    selectedBagSize: treeCreaClutch
      .questions.find(({ slug }) => slug === 'size')
      .answers.find(({ slug }) => slug === 'BIG'),
    selectedCategory: 'clothes',
    selectedCloth: 'acce',
    selectedCreationNumberOfPieces: 1,
    selectedFabric: 'other',
    selectedTypeOfWork: 'creation',
    otherItems: ['7.crea_poch-ferm-6', '7.crea_poch-supp-3', '7.crea_poch-supp-12'],
  },
  balzacBigBag: {
    ...getInitialState(),
    selectedAccessoryCreation: 'bag',
    selectedBagClosing: treeCreaBag
      .questions.find(({ slug }) => slug === 'closing')
      .answers.find(({ slug }) => slug === 'pressStudClosing'),
    selectedBagHandle: treeCreaBag
      .questions.find(({ slug }) => slug === 'handle')
      .answers.find(({ slug }) => slug === 'chainHandle'),
    selectedBagShape: treeCreaBag
      .questions.find(({ slug }) => slug === 'shape')
      .answers.find(({ slug }) => slug === 'SIMPLE'),
    selectedBagSize: treeCreaBag
      .questions.find(({ slug }) => slug === 'size')
      .answers.find(({ slug }) => slug === 'BIG'),
    selectedCategory: 'clothes',
    selectedCloth: 'acce',
    selectedCreationNumberOfPieces: 1,
    selectedFabric: 'other',
    selectedTypeOfWork: 'creation',
    otherItems: ['7.crea_sac-supp-3'],
  },
};

export default prefilledClothes;
