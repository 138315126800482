const fabricsListOfAccessories = [
  {
    name: 'Coton écru de Balzac',
    slug: 'coton-ecru-balzac',
  },
  {
    name: 'Coton N&B de Balzac',
    slug: 'coton-nb-balzac',
  },
  {
    name: 'Coton rouge de Balzac',
    slug: 'coton-rouge-balzac',
  },
  {
    name: 'Les fleurs de Cyrillus',
    slug: 'fleurs-cyrillus',
  },
  {
    name: 'Le bleu du Jacquard Français',
    slug: 'bleu-jacquard-francais',
  },
  {
    name: 'Les groseilles de Cyrillus',
    slug: 'groseilles-cyrillus',
  },
  {
    name: 'Coton beige de Madura',
    slug: 'coton-beige-madura',
  },
  {
    name: "J'ai un tissu",
    slug: 'tissu',
  },
];

export const getFabricNameOfAccessories = (fabricSlug) => {
  if (!fabricSlug) return null;
  const result = fabricsListOfAccessories.find(fabric => fabric.slug === fabricSlug);
  return result.name;
};

export default fabricsListOfAccessories;
