import React from 'react';
import PropTypes from 'prop-types';

import withOrderContext from '../../withOrderContext';
import SlotsSummary from './SlotsSummary';
import { renderCheckbox } from './ContactForm';

import iconBasket from '../../assets/order/iconBasket.svg';
import iconChecked from '../../assets/order/iconChecked.svg';
import iconCalendar from '../../assets/order/iconCalendar.svg';
import imageSwarovskiBanner from '../../assets/swarovskiBanner.jpg';
import OrderSummaryCloth from './OrderSummaryCloth';
import {
  Ul, Li, Line, PromoCodeForm, FormInput, Button, ErrorP, SwarovskiBanner, CheckBoxContainer,
  SwarovskiTextContainer, ItalicText, BoldSpan, SwarovskiImg, CalendarContainer, StyledSubtitle,
  TotalRow, SubTotalRow, SubContainer, PromoCode, Container, TransformationText, OrderContainer,
  FlexContainer, Span, Code,
} from './orderSummaryStyledComponents';

const renderInfosContainer = vertical => (
  <>
    <Ul vertical={vertical}>
      <Li icon={iconChecked}>
        Votre devis n’est pas figé, vous pourrez modifier la liste de travaux lors du RDV
      </Li>
      <Li icon={iconChecked}>
        Vous n’êtes pas prélevé avant la validation du devis final avec le couturier
      </Li>
      <Li icon={iconChecked}>
        Recevez vos vêtements retouchés en 3 à 5 jours après le RDV
      </Li>
    </Ul>
    <Line marginBottom={10} />
  </>
);

const renderPromoCodeForm = (vertical, promoCode, setPromoCode, checkPromoCode, errorPromoCode) => (
  <div>
    <PromoCodeForm vertical={vertical}>
      <FormInput
        name="promoCode" value={promoCode.code || ''}
        type="text"
        placeholder="Code promo"
        onChange={event => setPromoCode(event.target.value)}
      />
      <Button onClick={() => checkPromoCode(promoCode.code)}>Valider</Button>
      {errorPromoCode && <ErrorP>Ce code promo n’est pas valide</ErrorP>}
    </PromoCodeForm>
  </div>
);

const renderSwarovskiBanner = (useSwarovskiPromoCode, toggleSwarovskiPromoCode) => (
  <SwarovskiBanner>
    <CheckBoxContainer>
      {renderCheckbox(useSwarovskiPromoCode, toggleSwarovskiPromoCode)}
      <SwarovskiTextContainer>
        <div>
          Je souhaite bénéficier de la collab Swarovski pour embellir mes tissus de pierres et perles.
        </div>
        <ItalicText>
          <BoldSpan>Pierres offertes</BoldSpan> sous réserve de stock disponible.
        </ItalicText>
      </SwarovskiTextContainer>
    </CheckBoxContainer>
    <SwarovskiImg src={imageSwarovskiBanner} alt="Profitez de la collab Tilli avec Swarovski" />
  </SwarovskiBanner>
);

const renderSlots = selectedDates => (
  <>
    <Line marginTop={10} />
    <CalendarContainer>
      <StyledSubtitle left>
        <img src={iconCalendar} alt="Calendrier" />{' Créneaux sélectionnés'}
      </StyledSubtitle>
      <SlotsSummary selectedDates={selectedDates} noMargin />
    </CalendarContainer>
  </>
);

class OrderSummary extends React.Component {
  componentDidMount() {
    const { trackTotalPaid, totalPaid, onTotalPaid } = this.props;
    if (!isNaN(totalPaid)) {
      onTotalPaid(totalPaid);
      trackTotalPaid(totalPaid);
    }
  }

  componentDidUpdate() {
    const { trackTotalPaid, totalPaid, onTotalPaid } = this.props;
    if (!isNaN(totalPaid)) {
      onTotalPaid(totalPaid);
      trackTotalPaid(totalPaid);
    }
  }

  renderSubTotal() {
    const {
      clothes, totalClothes, hasDefinedPrice, isRDV3Clicks,
    } = this.props;
    const hasUpcyclingWithoutPriceRange = clothes
      .reduce((acc, cloth) => acc || (cloth.typeOfWork === 'transformation' && cloth.upcyclingItems.length === 0), false);
    return (!hasDefinedPrice || isRDV3Clicks
      ? <TotalRow>Sous-Total <span>Estimation en RDV</span></TotalRow>
      :
      <TotalRow>
        Sous-Total <span>{totalClothes} € {hasUpcyclingWithoutPriceRange ? ' + estimation en RDV' : ''}</span>
      </TotalRow>
    );
  }

  renderClothes() {
    const {
      clothes, canEdit, orderContext: { editingClothIndex },
      setEditingClothIndex, deleteCloth, isRDV3Clicks, duplicateCloth, rawClothes,
    } = this.props;
    return clothes.map((cloth) => {
      const priceRange = cloth.upcyclingItems.reduce(
        (acc, upcyclingItem) => acc + (upcyclingItem.priceRange || 0),
        0,
      );
      return (
        <OrderSummaryCloth
          key={`${cloth.index}_${cloth.name}`}
          canEdit={canEdit}
          cloth={cloth}
          clothes={rawClothes}
          deleteCloth={deleteCloth}
          duplicateCloth={duplicateCloth}
          editingClothIndex={editingClothIndex}
          isRDV3Clicks={isRDV3Clicks}
          priceRange={priceRange}
          setEditingClothIndex={setEditingClothIndex}
        />
      );
    });
  }

  renderMinOrderAmount() {
    const { minOrderAmountFee, clothes, isRDV3Clicks } = this.props;
    const hasUpcycling = clothes
      .reduce((acc, cloth) => acc || cloth.typeOfWork === 'transformation', false);
    return (minOrderAmountFee > 0 || isRDV3Clicks) && (
      <div>
        <TotalRow grey>Minimum de commande <span>20 €</span></TotalRow>
        <SubTotalRow grey>
          Nos Tillistes se déplacent pour un minimum de 20€
          {((!hasUpcycling && !isRDV3Clicks) || hasUpcycling)
            ? ' , d’ici votre rendez-vous, vous trouverez bien un vêtement qui a besoin d’un coup de neuf :)'
            : ''
          }
        </SubTotalRow>
      </div>);
  }

  renderDeliveryFee() {
    const { deliveryFee, hasZipcode } = this.props;
    return (
      <>
        <TotalRow>Frais de déplacement <span>{deliveryFee} €</span></TotalRow>
        {!hasZipcode &&
          <SubTotalRow>Les frais de déplacement vous seront précisés en fonction de votre code postal</SubTotalRow>
        }
        <TotalRow>Frais de livraison <span>Offerts</span></TotalRow>
      </>
    );
  }

  renderTotal() {
    const {
      totalPaid, clothes, hasDefinedPrice, isRDV3Clicks, discountVouchersValue,
    } = this.props;
    const hasUpcyclingWithoutPriceRange = clothes
      .reduce((acc, cloth) => acc || (cloth.typeOfWork === 'transformation' && cloth.upcyclingItems.length === 0), false);
    return !hasDefinedPrice || isRDV3Clicks
      ? (<TotalRow>Total estimé <span>Estimation en RDV</span></TotalRow>)
      : (<TotalRow>
        Total estimé
        <span>
          {totalPaid - discountVouchersValue > 0
            ? totalPaid - discountVouchersValue : 0} € {hasUpcyclingWithoutPriceRange ? ' + estimation en RDV'
            : ''}
        </span>
      </TotalRow>);
  }

  renderRightBlock() {
    const {
      selectedDates, vertical, promoCode, setPromoCode, hasPromoCode, setHasPromoCode, checkPromoCode,
      errorPromoCode, useSwarovskiPromoCode, toggleSwarovskiPromoCode,
    } = this.props;
    return (
      <SubContainer vertical={vertical}>
        {renderInfosContainer(vertical)}
        {!hasPromoCode && !(promoCode && !isNaN(promoCode.value)) && (
          <PromoCode onClick={setHasPromoCode}>J’ai un code promo</PromoCode>
        )}
        {hasPromoCode && !(promoCode && !isNaN(promoCode.value)) && (
          renderPromoCodeForm(vertical, promoCode, setPromoCode, checkPromoCode, errorPromoCode)
        )}
        {!process.env.GATSBY_BRAND && renderSwarovskiBanner(useSwarovskiPromoCode, toggleSwarovskiPromoCode)}
        {selectedDates && Object.keys(selectedDates).length > 0 && renderSlots(selectedDates)}
      </SubContainer>
    );
  }

  render() {
    const { vertical, clothes, promoCode, discountVouchersValue, isPromoCodeDisplayed } = this.props;
    const hasUpcycling = clothes.reduce((acc, cloth) => acc || cloth.typeOfWork === 'transformation', false);

    return (
      <Container vertical={vertical}>
        <StyledSubtitle left>
          <img src={iconBasket} alt="Panier" />{' Votre estimation'}
        </StyledSubtitle>
        {hasUpcycling &&
          <TransformationText>
            Pour les transformations, le couturier réalisera le devis directement sur place.<br />
            C’est lui le mieux placé pour vous recommander les travaux de couture à effectuer :)
          </TransformationText>
        }
        <Line marginTop={14} noMarginBottom />
        <OrderContainer vertical={vertical}>
          <SubContainer vertical={vertical}>
            {this.renderClothes(clothes)}
            <Line />
            {this.renderSubTotal()}
            {this.renderDeliveryFee()}
            {this.renderMinOrderAmount()}

            {(promoCode && !isNaN(promoCode.value)) &&
              <TotalRow>
                <FlexContainer>Code promo{isPromoCodeDisplayed && <Code>{promoCode.code}</Code>}</FlexContainer>
                <Span>- {promoCode.value} {promoCode.unit === 'EUROS' ? '€' : '%'}</Span>
              </TotalRow>
            }

            {discountVouchersValue > 0 &&
              <TotalRow>
                Crédits parrainage
                <Span>- {discountVouchersValue} €</Span>
              </TotalRow>
            }

            <Line />

            {this.renderTotal()}
          </SubContainer>

          {this.renderRightBlock()}
        </OrderContainer>
      </Container>
    );
  }
}

OrderSummary.propTypes = {
  deliveryFee: PropTypes.number.isRequired,
  minOrderAmountFee: PropTypes.number.isRequired,
  totalClothes: PropTypes.number.isRequired,
  totalPaid: PropTypes.number.isRequired,
  clothes: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape({
      slug: PropTypes.string,
    })),
    typeOfWork: PropTypes.string,
    total: PropTypes.number.isRequired,
    fabrics: PropTypes.arrayOf(PropTypes.string),
  })),
  selectedDates: PropTypes.shape({}),
  vertical: PropTypes.bool,
  promoCode: PropTypes.shape({}),
  setPromoCode: PropTypes.func.isRequired,
  setHasPromoCode: PropTypes.func.isRequired,
  checkPromoCode: PropTypes.func.isRequired,
  hasPromoCode: PropTypes.bool,
  errorPromoCode: PropTypes.bool,
  canEdit: PropTypes.bool,
  setEditingClothIndex: PropTypes.func.isRequired,
  deleteCloth: PropTypes.func.isRequired,
  orderContext: PropTypes.shape({
    editingClothIndex: PropTypes.number,
    rdv3ClicksOrder: PropTypes.shape({
      suitAlterations: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        slug: PropTypes.string,
      })),
    }),
  }).isRequired,
  hasDefinedPrice: PropTypes.bool,
  discountVouchersValue: PropTypes.number.isRequired,
  isRDV3Clicks: PropTypes.bool.isRequired,
  hasZipcode: PropTypes.bool,
  isPromoCodeDisplayed: PropTypes.bool,
  useSwarovskiPromoCode: PropTypes.bool,
  toggleSwarovskiPromoCode: PropTypes.func,
  trackTotalPaid: PropTypes.func,
  onTotalPaid: PropTypes.func,
  duplicateCloth: PropTypes.func,
  rawClothes: PropTypes.arrayOf(PropTypes.shape({})),
};

OrderSummary.defaultProps = {
  orderItems: [],
  selectedDates: null,
  vertical: false,
  orderClothName: '',
  clothes: [],
  promoCode: null,
  hasPromoCode: false,
  errorPromoCode: false,
  canEdit: false,
  hasDefinedPrice: false,
  hasZipcode: false,
  isPromoCodeDisplayed: true,
  useSwarovskiPromoCode: false,
  toggleSwarovskiPromoCode() {},
  trackTotalPaid() {},
  onTotalPaid() {},
};

export default withOrderContext(OrderSummary);
