import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors, mobileThresholdPixels, fontSizes } from './styledComponents';
import placeholderImg from '../../../assets/benefits/placeholder.svg';
import sewingImg from '../../../assets/benefits/sewing.svg';
import euroImg from '../../../assets/benefits/euro.svg';
import giftImg from '../../../assets/benefits/gift.svg';
import placeholderImgNavy from '../../../assets/benefits/placeholderNavy.svg';
import sewingImgNavy from '../../../assets/benefits/sewingNavy.svg';
import euroImgNavy from '../../../assets/benefits/euroNavy.svg';
import giftImgNavy from '../../../assets/benefits/giftNavy.svg';
import withAppContext from '../../../withAppContext';

const Background = styled.div`
  height: 160px;
  background-color: ${props => props.backgroundColor ? colors[props.backgroundColor] : colors.navy};
  display: flex;
  justify-content: space-around;
  align-items: center;

  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: column;
    align-items: stretch;
    padding: 40px 0px 10px;
    height: unset;
  }
`;

const Benefit = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @media (max-width: ${mobileThresholdPixels}) {
    margin-bottom: 30px;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 18px 0px 18px 15px;

  @media (max-width: ${mobileThresholdPixels}) {
    flex: 3;
    margin: 0px;
  }
`;

const Icon = styled.img`
  @media (max-width: ${mobileThresholdPixels}) {
    flex: 1;
    margin-left: 43px;
    margin-right: 20px;
    height: 33px;
  }
`;

const Text = styled.p`
  margin: 0px;
  max-width: 205px;
  color: ${props => props.color ? colors[props.color] : colors.white};
  font-size: ${fontSizes.m};
  line-height: ${fontSizes.xl};
  font-family: Roboto;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.xs};
    line-height: ${fontSizes.m};
  }
`;

const benefits = [
  {
    img: placeholderImg,
    imgNavy: placeholderImgNavy,
    text1Desktop: 'RDV chez vous',
    text2Desktop: 'de 7h à 22h, 7j/7',
    text1Mobile: 'RDV chez vous',
    text2Mobile: 'de 7h à 22h, 7j/7',
  },
  {
    img: sewingImg,
    imgNavy: sewingImgNavy,
    text1Desktop: 'Couturiers pro,',
    text2Desktop: 'sélectionnés avec soin',
    text1Mobile: 'Couturiers pro, sélectionnés',
    text2Mobile: 'avec soin',
  },
  {
    img: euroImg,
    imgNavy: euroImgNavy,
    text1Desktop: 'Au même prix que la',
    text2Desktop: 'retoucherie d’en bas',
    text1Mobile: 'Au même prix que la',
    text2Mobile: 'retoucherie d’en bas',
  },
  {
    img: giftImg,
    imgNavy: giftImgNavy,
    text1Desktop: 'Livraison',
    text2Desktop: 'sous 3 jours',
    text1Mobile: 'Livraison sous 3 jours',
    text2Mobile: '',
  },
];

const BenefitsBanner = ({ context: { isMobile }, color, backgroundColor, altTag }) => (
  <Background backgroundColor={backgroundColor} >
    {benefits.map(benefit => (
      <Benefit key={benefit.text1Desktop}>
        <Icon
          src={color !== 'navy' ? benefit.img : benefit.imgNavy}
          alt={altTag}
        />
        <TextContainer color={color}>
          <Text color={color}>{!isMobile ? benefit.text1Desktop : benefit.text1Mobile}</Text>
          <Text color={color}>{!isMobile ? benefit.text2Desktop : benefit.text2Mobile}</Text>
        </TextContainer>
      </Benefit>
    ))}
  </Background>
);

BenefitsBanner.propTypes = {
  context: PropTypes.shape({
    isMobile: PropTypes.bool,
  }).isRequired,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  altTag: PropTypes.string,
};
BenefitsBanner.defaultProps = {
  color: 'white',
  backgroundColor: 'navy',
  altTag: 'Tilli Tili Tilly retoucherie à domicile',
};

export default withAppContext(BenefitsBanner);
