import silk from '../../assets/order/fabrics/silk.jpg';
import suit from '../../assets/order/fabrics/suit.jpg';
import jeans from '../../assets/order/fabrics/jeans.jpg';
import lace from '../../assets/order/fabrics/lace.jpg';
import leather from '../../assets/order/fabrics/leather.jpg';
import stitch from '../../assets/order/fabrics/stitch.jpg';
import other from '../../assets/order/fabrics/other.jpg';
import fur from '../../assets/order/fabrics/fur.jpg';
import sportAndBath from '../../assets/order/fabrics/sportAndBath.jpg';

const fabrics = [
  { name: 'Jeans', slug: 'jeans', image: jeans },
  { name: 'Soie ou mousseline', slug: 'silk', image: silk },
  { name: 'Costume', slug: 'suit', image: suit },
  { name: 'Dentelle', slug: 'lace', image: lace },
  { name: 'Maille', slug: 'stitch', image: stitch },
  {
    name: 'Fourrure',
    slug: 'fur',
    image: fur,
    filterContent: { title: 'Bientôt disponible', text: 'Nous n’avons pas encore d’experts dans cette catégorie.' },
  },
  {
    name: 'Cuir',
    slug: 'leather',
    image: leather,
    filterContent: {
      alwaysShowOnMobile: true,
      showOnHover: true,
      title: 'Attention !',
      text: 'Pour le moment, nous traitons que le cuir léger comme les hauts & pantalons (pas de blouson).',
    },
  },
  { name: 'Autre (coton, jersey...)', slug: 'other', image: other },
  { name: 'Sport & bain', slug: 'bath', image: sportAndBath },
];

export function getFabricName(fabricSlug) {
  if (!fabricSlug) return null;
  const fabric = fabrics.find(fabricIt => fabricIt.slug === fabricSlug);
  return fabric && fabric.name;
}

export default fabrics;
