import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { navigate } from 'gatsby';


import tree, { treeInverse } from './tree';
import { pricingInverse, metadata } from '../../services/pricing';
import { formatClothOrPiece, formatSlots } from '../../services/orderFormatting';
import { pushToLayer } from '../../services/googleTagManager';
import { getZone } from '../../core/services/zipcode';
import routesMap from '../../Routes';

import withOrderContext from '../../withOrderContext';
import withCustomerContext from '../../withCustomerContext';
import {
  StyledTitle2 as Title2V3, mobileThresholdPixels, colors,
} from '../home/v3/styledComponents';
import OrderSummaryContainer from '../../components/order/OrderSummaryContainer';
import callApi from '../../services/api';
import { checkZipcodeErrorType, getLocality, getDeliveryFees } from '../../services/zipcode';
import { isEmailValid, isPhoneValid } from '../../services/checkingFormat';
import LogoButton from './LogoButton';
import tick from '../../assets/tick.png';
import OutInSideClick from '../home/v2/OutInSideClick';
import Feedback from '../home/v3/Feedback';
import Dots from '../home/v3/Dots';
import ContactInfos from './ContactInfos';
import photoElodie from '../../assets/feedbacks/elodie.png';
import photoThomas from '../../assets/feedbacks/thomas.png';
import { inputs, inputsAlreadyCustomer } from './meetingInfosFormInputs';
import PasswordForgottenModal from '../MyAccount/PasswordForgottenModal';
import customerFeedbacks from '../home/v3/customerFeedbacks';
import { upcyclingPricingInverse } from '../../core/pricings/upcyclingPricing';

const ColoredSpan = styled.span`
  color: ${props => props.error ? colors.red : colors.navy};
`;

const Title2 = styled(Title2V3)`
  margin-bottom: 81px;
  @media (max-width: ${mobileThresholdPixels}) {
    margin-bottom: 30px;
    margin-bottom: 6px;
  }
`;

const MeetingInfosContainer = styled.div`
  width: 100%;
`;

const ComponentsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  ${props => props.marginBottom && 'margin-bottom: 100px;'}
  ${props => props.marginTop && 'margin-top: 60px;'}
  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: column;
    align-items: center;
    ${props => props.marginBottom && 'margin-bottom: 60px;'}
    margin-top: 0px;
  }
`;

const PopUpWaitTimeContainer = styled.div`
  position: fixed;
  background-color: ${colors.opacityFilter};
  width: 100vw;
  height: 100vw;
  left: 0;
  top: 0;
  z-index: 10;
`;

const PopUpContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: auto;
  margin: 0 auto;
  display: ${props => props.isDisplayed ? 'flex' : 'none'};
  align-items: center;
  z-index: 2;
  @media (max-width: ${mobileThresholdPixels}) {
    width: auto;
    padding: 18px 22px;
  }
`;

const PopUpLocality = styled(PopUpContainer)`
  background-color: ${colors.popup};
  color: ${colors.white};
  font-family: Roboto;
  font-size: 14px;
  line-height: 20px;
  width: 373px;
  padding: 34px 67px 34px 40px;

  @media (max-width: ${mobileThresholdPixels}) {
    width: calc(100vw - 107px);
  }
`;

const PopUpWaitTime = styled(PopUpContainer)`
  background-color: ${colors.white};
  color: ${colors.navy};
  font-family: Roboto;
  font-size: 18px;
  width: 307px;
  padding: 54px 77px;
  flex-direction: column;

  @media (max-width: ${mobileThresholdPixels}) {
    width: calc(100vw - 50px);
    padding: 54px 25px;
  }
`;

const PopUpTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 49px;
  @media (max-width: ${mobileThresholdPixels}) {
    margin-right: 17px;
  }
`;

const PopUpText = styled.p`
  margin-top: ${props => props.marginTop ?? 0}px;
  margin-bottom: 5px;
  ${props => props.pointer && 'cursor: pointer;'}
`;

const StyledLink = styled.div`
  text-decoration: none;
  @media (max-width: ${mobileThresholdPixels}) {
    order: -1;
  }
`;


const feedbacks = [
  {
    photo: photoElodie,
    name: 'Elodie',
    jobDescription: 'a fait retoucher ses rideaux par Gabriel',
    // eslint-disable-next-line max-len
    feedback: 'Bonjour je suis très satisfaite des services de Gabriel mes rideaux ont été parfaitement retouchés dans un délai record avec un tarif tout à fait correct pour la prestation. Donc pour la note de satisfaction c’est 10.',
    rating: 5,
  },
  {
    photo: photoThomas,
    name: 'Thomas',
    jobDescription: 'a fait retoucher ses costumes par Alexandra',
    // eslint-disable-next-line max-len
    feedback: 'Tilliste réactive et agréable + réactivité et sympathie du service client sur l’application. Je vais parrainer des personnes.',
    rating: 5,
  },
];

const getZipcodeErrorLabel = message => (
  <ColoredSpan error>
    {message} <br />
    <ColoredSpan>
      Nous contacter si vous êtes proches des villes ci-dessous : Paris, Lyon, Bordeaux, Marseille & Aix-en-Provence
    </ColoredSpan>
  </ColoredSpan>
);

function trackOrder(order, isFirstOrder, promoCode) {
  const funnelType = order.clothes && order.clothes.length > 0 ? 'standard' : 'lite';
  const zone = getZone(order.fullAddress.zipcode);
  pushToLayer({
    event: 'Order Sent',
    funnel_type: funnelType,
    area: zone && zone.id,
    order_id: order._id,
    promo_code: promoCode ?? '',
    is_first_order: !!isFirstOrder,
    is_logged_in: true,
    order_amount: Math.round(order.totalPaid * 100),
    user_id: typeof order.customer === 'object'
      ? order.customer._id
      : order.customer,
  });
}

function hasEmbroidery(clothes) {
  return clothes.reduce((hasEmbroideryIt, cloth) => {
    const { embroideries } = cloth;
    if (
      embroideries[0].isActive || embroideries[1].isActive ||
      embroideries[2].isActive || embroideries[3].isActive
    ) return true;
    return hasEmbroideryIt;
  }, false);
}

const getCompleteFabrics = (clothes = [], rdv3ClicksOrder = {}) => {
  let completeFabrics = [];
  if (clothes.length !== 0) {
    completeFabrics = clothes.reduce(
      (fabricsAcc, { selectedFabric }) => selectedFabric !== ''
        ? [...fabricsAcc, selectedFabric]
        : [...fabricsAcc]
      , []);
    if (hasEmbroidery(clothes)) completeFabrics.push('embroidery');
    if (clothes.find(cloth => cloth.selectedCloth === 'mari')) completeFabrics = [...completeFabrics, 'silk', 'lace'];
    if (clothes.find(cloth => cloth.selectedPiece === 'ride')) completeFabrics.push('curtain');
    if (clothes.find(cloth => cloth.selectedCloth === 'ling')) completeFabrics.push('lingerie');
    if (clothes.find(cloth => cloth.selectedCloth === 'mail')) completeFabrics.push('bath');
    if (clothes.find(cloth => cloth.selectedCloth === 'pull')) completeFabrics.push('stitch');
    if (clothes.find(cloth => cloth.selectedTypeOfWork === 'creation')) completeFabrics.push('creation');
  } else {
    completeFabrics = rdv3ClicksOrder.fabrics;
    const suitAlterations = rdv3ClicksOrder.suitAlterations?.map(({ slug }) => slug);
    rdv3ClicksOrder.knowHow.forEach(({ slug }) => {
      if (slug === 'mari') {
        completeFabrics.push('silk', 'lace');
      } else if (slug === 'coat') {
        completeFabrics.push('suit');
      } else if (slug !== 'suit' || !suitAlterations?.includes('nothingComplex')) {
        completeFabrics.push(slug);
      }
    });
  }

  return completeFabrics.filter(fabric => fabric !== 'other');
};

const getClothesWithSuitPiecesSplitted = (clothes) => {
  const clothesWithSuitPiecesSplitted = [];
  clothes.forEach((cloth) => {
    if (cloth.selectedCloth === 'cost' && cloth.selectedTypeOfWork === 'alteration') {
      cloth.suitPieces.forEach((suitPiece) => {
        const problems = {};
        cloth.suitProblems.filter(suitProblem => suitProblem.includes(`${suitPiece}_`)).forEach((suitProblem) => {
          const suitProblemSlug = suitProblem.replace(`${suitPiece}_`, '');
          problems[suitProblemSlug] = [];
          cloth.suitItems.filter(({ piece, problem }) => piece === suitPiece && problem === suitProblemSlug).forEach(({ isMultiple, slug, quantity }) => {
            const suitItemSlug = `${suitPiece}_${slug}`;
            problems[suitProblemSlug].push(suitItemSlug);
            if (isMultiple) problems[`${suitItemSlug}_multiple`] = [quantity];
          });
        });
        clothesWithSuitPiecesSplitted.push({
          ...cloth,
          selectedProblems: problems,
          selectedLining: cloth.suitPieceLinings[suitPiece] || '',
          suitPiece,
          selectedFabric: cloth.suitItems.filter(({ piece }) => piece === suitPiece).every(({ isNotSuit }) => isNotSuit) ? '' : 'suit',
        });
      });
    } else {
      clothesWithSuitPiecesSplitted.push(cloth);
    }
  });
  return clothesWithSuitPiecesSplitted;
};

class MeetingInfos extends Component {
  constructor() {
    super();
    this.state = {
      isLoggedIn: false,
      isAlreadyCustomer: false,
      formInputs: { ...inputs },
      formInputsAlreadyCustomer: { ...inputsAlreadyCustomer },
      popUpLocalityIsOpen: false,
      popUpWaitTimeIsOpen: false,
      deliveryFee: 5,
      selectedAddress: {},
      addingNewAddress: false,
      addressList: [],
      isSubscribingToNewsletter: false,
    };
    this.setIsAlreadyCustomer = this.setIsAlreadyCustomer.bind(this);
    this.setIsLoggedIn = this.setIsLoggedIn.bind(this);
    this.setFormInputValue = this.setFormInputValue.bind(this);
    this.checkFormInputValue = this.checkFormInputValue.bind(this);
    this.login = this.login.bind(this);
    this.setPopUpIsOpen = this.setPopUpIsOpen.bind(this);
    this.submitOrder = this.submitOrder.bind(this);
    this.displayDeliveryFees = this.displayDeliveryFees.bind(this);
    this.updateDeliveryFees = this.updateDeliveryFees.bind(this);
    this.onSelectAddress = this.onSelectAddress.bind(this);
    this.setCustomerAndOrderId = this.setCustomerAndOrderId.bind(this);
    this.addAddress = this.addAddress.bind(this);
    this.displayAddressesList = this.displayAddressesList.bind(this);
    this.setFormInputs = this.setFormInputs.bind(this);
    this.setCustomer = this.setCustomer.bind(this);
    this.setIsAlreadyCustomerWhenEmailChecked = this.setIsAlreadyCustomerWhenEmailChecked.bind(this);
  }

  componentDidMount() {
    const { customerContext: { customer } } = this.props;
    if (customer !== undefined) {
      this.setCustomer();
    }
  }

  componentDidUpdate(prevProps) {
    const { customerContext: { customer } } = this.props;
    if (!prevProps.customerContext.customer && !!customer) {
      this.setCustomer();
    }
  }

  onSelectAddress(address) {
    this.displayDeliveryFees(address.zipcode);
    this.setState({
      selectedAddress: { ...address },
    });
  }

  setCustomer() {
    const { customerContext: { customer } } = this.props;
    this.setIsAlreadyCustomer(true);
    this.setIsLoggedIn(true);
    this.displayAddressesList(true);

    const { formInputs } = this.state;
    const newFormInputs = { ...formInputs };
    newFormInputs.firstname.value = customer.firstname;
    newFormInputs.firstname.error = undefined;
    newFormInputs.firstname.hasError = false;
    newFormInputs.lastname.value = customer.lastname;
    newFormInputs.lastname.error = undefined;
    newFormInputs.lastname.hasError = false;
    newFormInputs.email.value = customer.email;
    newFormInputs.email.error = undefined;
    newFormInputs.email.hasError = false;
    newFormInputs.phone.value = customer.phone;
    newFormInputs.phone.error = undefined;
    newFormInputs.phone.hasError = false;
    this.setFormInputs(formInputs);
  }

  setFormInputs(formInputs) {
    this.setState({ formInputs });
  }

  setIsAlreadyCustomer(isAlreadyCustomer) {
    this.setState({ isAlreadyCustomer });
  }

  setIsLoggedIn(isLoggedIn) {
    this.setState({ isLoggedIn });
  }

  setFormInputValue(event, listName) {
    const { target: { name, value } } = event;
    const newFormInputs = { ...this.state[listName] };
    if (name === 'zipcode') {
      newFormInputs.locality.value = getLocality(value) ?? '';
    }
    newFormInputs[name].value = value;
    if (listName === 'formInputsAlreadyCustomer' || name !== 'email') {
      this.setState({ [listName]: { ...newFormInputs } }, this.checkFormInputValue(name, listName, false));
    } else {
      const isValid = isEmailValid(value);
      if (isValid) {
        callApi(`public/checkEmail/${value}`, 'get')
          .then(() => {
            newFormInputs.email.more.isActive = true;
            this.setState({ [listName]: { ...newFormInputs } }, this.checkFormInputValue(name, listName, false));
          })
          .catch(() => {
            newFormInputs.email.more.isActive = false;
            this.setState({ [listName]: { ...newFormInputs } }, this.checkFormInputValue(name, listName, false));
          });
      } else {
        newFormInputs.email.more.isActive = false;
        this.setState({ [listName]: { ...newFormInputs } }, this.checkFormInputValue(name, listName, false));
      }
    }
  }

  setIsAlreadyCustomerWhenEmailChecked() {
    const formInputsAlreadyCustomer = { ...this.state.formInputsAlreadyCustomer };
    formInputsAlreadyCustomer.email.value = this.state.formInputs.email.value;
    formInputsAlreadyCustomer.email.error = false;
    formInputsAlreadyCustomer.errorEmail.isActive = false;
    this.setState({
      isAlreadyCustomer: true,
      formInputsAlreadyCustomer: { ...formInputsAlreadyCustomer },
    });
  }

  setPopUpIsOpen(popUpLocalityIsOpen) {
    this.setState({ popUpLocalityIsOpen });
  }

  setCustomerAndOrderId(customer, orderId) {
    const {
      customerContext: { setCustomer },
      orderContext: { setOrderId },
    } = this.props;
    setCustomer(customer);
    setOrderId(orderId);
  }

  displayDeliveryFees(zipcode) {
    const deliveryFees = getDeliveryFees(zipcode) ?? 0;
    this.setState({
      deliveryFees,
    });
    this.updateDeliveryFees(deliveryFees);
    const zone = getZone(zipcode);
    if (zone && zone.id) pushToLayer({ area: zone.id });
    if (deliveryFees === 15) {
      pushToLayer({
        event: 'Order Funnel - Higher Fees Message Displayed',
        area: zone.id,
      });
      this.setPopUpIsOpen(true);
    }
  }

  displayAddressesList(mustSetAddress = false) {
    const { customerContext: { customer } } = this.props;
    const addressesList = [
      { ...customer.address, _id: 'initialAddress' },
      ...customer.addresses,
    ].filter(address => address.street);
    if (mustSetAddress && addressesList.length > 0) {
      const formInputs = { ...this.state.formInputs };
      formInputs.street.value = addressesList[0].street;
      formInputs.street.error = undefined;
      formInputs.street.hasError = false;
      formInputs.zipcode.value = addressesList[0].zipcode;
      formInputs.zipcode.error = undefined;
      formInputs.zipcode.hasError = false;
      formInputs.errorZipcode.isActive = false;
      formInputs.locality.value = addressesList[0].locality;
      formInputs.locality.error = undefined;
      formInputs.locality.hasError = false;
      formInputs.comment.value = addressesList[0].comment;
      formInputs.comment.error = undefined;
      this.setState({ formInputs: { ...formInputs } });
    }
    this.setState({
      addressesList,
      selectedAddress: addressesList[0],
      addingNewAddress: !(addressesList && addressesList.length > 1),
    });
    this.displayDeliveryFees(this.state.selectedAddress.zipcode);
    return addressesList;
  }

  addAddress() {
    this.setIsAlreadyCustomer(true);
    const { formInputs } = this.state;
    const newFormInputs = { ...formInputs };
    newFormInputs.street.value = '';
    newFormInputs.comment.value = '';
    newFormInputs.zipcode.value = '';
    newFormInputs.locality.value = '';
    newFormInputs.street.error = undefined;
    newFormInputs.zipcode.error = undefined;
    newFormInputs.locality.error = undefined;
    newFormInputs.street.hasError = undefined;
    newFormInputs.zipcode.hasError = undefined;
    newFormInputs.locality.hasError = undefined;
    this.setState({
      addingNewAddress: true,
      formInputs: { ...newFormInputs },
    });
  }

  login({ accessToken } = {}) {
    const { formInputsAlreadyCustomer: { email, password } } = this.state;
    const request = accessToken
      ? { fbAccessToken: accessToken }
      : { email: email.value, password: password.value };
    callApi('login', 'post', request)
      .then(({ customer }) => {
        const {
          _id, firstname, lastname, phone, discountVouchers, email: customerEmail,
        } = customer;
        const address = customer.address && customer.address.street ? customer.address : customer.addresses[0];
        const { street, comment, zipcode, locality } = address;
        pushToLayer({ event: 'Login', is_logged_in: true, user_id: _id });
        const { formInputs } = this.state;
        const newFormInputs = { ...formInputs };
        newFormInputs.firstname.value = firstname ?? '';
        newFormInputs.lastname.value = lastname ?? '';
        newFormInputs.street.value = street ?? '';
        newFormInputs.comment.value = comment ?? '';
        newFormInputs.zipcode.value = zipcode ?? '';
        newFormInputs.locality.value = locality ?? '';
        newFormInputs.email.value = customerEmail;
        newFormInputs.phone.value = phone;
        const {
          customerContext: { setCustomer, setCustomerDiscountVouchers },
        } = this.props;
        setCustomer(customer);
        setCustomerDiscountVouchers(discountVouchers);
        this.displayAddressesList();
        this.setState({
          formInputs: { ...newFormInputs },
          isLoggedIn: true,
        }, () => Object.keys(newFormInputs).forEach(formInput => this.checkFormInputValue(formInput, 'formInputs')));
      })
      .catch(() => {
        const { formInputsAlreadyCustomer } = this.state;
        const newFormInputs = { ...formInputsAlreadyCustomer };
        newFormInputs.errorConnection.isActive = true;
        newFormInputs.email.error = undefined;
        this.setState({
          formInputsAlreadyCustomer: { ...newFormInputs },
          isLoggedIn: false,
        });
      });
  }

  createCustomerRequest() {
    const { formInputs, selectedAddress, isAlreadyCustomer, addingNewAddress, isSubscribingToNewsletter } = this.state;
    const { customerContext: { customer } } = this.props;
    const { firstname, lastname, street, comment, zipcode, locality, phone, email } = formInputs;
    const addressFromForm = {
      street: street.value,
      comment: comment.value,
      zipcode: zipcode.value,
      locality: locality.value,
    };
    if (!isAlreadyCustomer || addingNewAddress) {
      const { addresses = [] } = customer ?? {};
      const address = addresses.find(addressIt =>
        addressIt.street === addressFromForm.street &&
        addressIt.zipcode === addressFromForm.zipcode &&
        addressIt.locality === addressFromForm.locality &&
        addressIt.comment === addressFromForm.comment,
      ) || addressFromForm;
      return {
        firstname: firstname.value,
        lastname: lastname.value,
        phone: phone.value,
        email: email.value,
        address,
        hasSubscribedToNewsletter: isSubscribingToNewsletter,
      };
    } return {
      firstname: customer.firstname,
      lastname: customer.lastname,
      phone: customer.phone,
      email: customer.email,
      address: selectedAddress,
    };
  }

  createOrderRequest(customer) {
    const { clothes: clothesProps, slots, rdv3ClicksOrder, promoCode, funnelType } = this.props.orderContext;
    const clothes = getClothesWithSuitPiecesSplitted(clothesProps);
    const rdv1Slots = formatSlots(slots);
    const brand = promoCode && promoCode.brand;

    return {
      rdv1: rdv1Slots[0],
      rdv1Slots,
      fabrics: getCompleteFabrics(clothes, rdv3ClicksOrder),
      promoCode: promoCode && promoCode.code,
      minOrderAmount: metadata.minOrderAmount,
      deliveryFee: getDeliveryFees(customer && customer.address && customer.address.zipcode),
      pricingVersion: metadata.version,
      clothes: clothes.map(cloth => formatClothOrPiece(tree, treeInverse, pricingInverse, upcyclingPricingInverse, cloth, brand)),
      address: customer && customer.address && customer.address._id, // eslint-disable-line no-underscore-dangle
      funnelType,
      funnelInfo: {
        clothes,
        rdv3ClicksOrder,
        customerHasSelectedFur: clothes.length !== 0 ? !!clothes.find(({ isFurSelected }) => isFurSelected) : rdv3ClicksOrder.isFurSelected,
      },
      creationFabrics: [...new Set([].concat(...clothes.map(item => item.selectedFabricOfAccessoriesCreation)))],
    };
  }

  createRequest() {
    const { customerContext: { customer: customerProps } } = this.props;
    const customer = this.createCustomerRequest();
    const order = this.createOrderRequest(customer);
    const { addingNewAddress, isSubscribingToNewsletter } = this.state;
    const customerHasSubscribedToNewsletter = customerProps && customerProps.hasSubscribedToNewsletter;
    const hasSubscribedToNewsletter = customerHasSubscribedToNewsletter || isSubscribingToNewsletter;

    if (addingNewAddress && !order.address) {
      return { address: customer.address, order, hasSubscribedToNewsletter };
    }
    return { customer, order, hasSubscribedToNewsletter };
  }

  checkFormInputValue(name, listName, mustDefineError = true) {
    const { isLoggedIn } = this.state;
    const newFormInputs = { ...this.state[listName] };
    if (name === 'zipcode') {
      const errorCode = checkZipcodeErrorType(newFormInputs.zipcode.value);
      if (mustDefineError) {
        newFormInputs.errorZipcode.isActive = errorCode !== 0;
        newFormInputs.zipcode.error = errorCode !== 0;
      }
      newFormInputs.zipcode.hasError = errorCode !== 0;
      if (errorCode === 1) {
        newFormInputs.errorZipcode.label = getZipcodeErrorLabel("Merci d'entrer un code postal à 5 chiffres");
      } else if (errorCode === 2) {
        newFormInputs.errorZipcode.label = getZipcodeErrorLabel('Ville pas encore desservie :(');
      }
      if (newFormInputs.locality.error === undefined && newFormInputs.locality.value !== '') {
        newFormInputs.locality.error = false;
        newFormInputs.locality.hasError = false;
      } else if (newFormInputs.locality.error !== undefined) {
        if (mustDefineError) {
          newFormInputs.locality.error = newFormInputs.locality.value === '';
        }
        newFormInputs.locality.hasError = newFormInputs.locality.value === '';
      }
      this.displayDeliveryFees(newFormInputs.zipcode.value);
    } else if (name === 'email') {
      const isValid = isEmailValid(newFormInputs.email.value);
      if (mustDefineError) {
        newFormInputs.errorEmail.isActive = !isValid;
        newFormInputs.email.error = !isValid;
      }
      newFormInputs.email.hasError = !isValid;
    } else if (name === 'phone' && !isLoggedIn) {
      const isValid = isPhoneValid(newFormInputs.phone.value);
      if (mustDefineError) {
        newFormInputs.errorPhone.isActive = !isValid;
        newFormInputs.phone.error = !isValid;
      }
      newFormInputs.phone.hasError = !isValid;
    } else if (newFormInputs[name].isRequired) {
      if (mustDefineError) {
        newFormInputs[name].error = newFormInputs[name].value === '';
      }
      newFormInputs[name].hasError = newFormInputs[name].value === '';
    } else {
      newFormInputs[name].error = newFormInputs[name].value === '' ? undefined : false;
      newFormInputs[name].hasError = false;
    }
    this.setState({ [listName]: { ...newFormInputs } }, () => this.checkIsDone());
  }

  checkIsDone() {
    const { formInputs } = this.state;
    return Object.values(formInputs)
      .reduce((acc, { isRequired, hasError }) => acc && (!isRequired || hasError === false), true);
  }

  updateDeliveryFees(deliveryFee) {
    this.props.orderContext.updateDeliveryFee(deliveryFee);
  }

  submitOrder() {
    this.setState({ popUpWaitTimeIsOpen: true });
    const { promoCode } = this.props.orderContext;
    const { isLoggedIn, addingNewAddress } = this.state;
    let endpoint = '';
    const request = this.createRequest();
    if (isLoggedIn && (!addingNewAddress || request.order.address)) {
      endpoint = 'createNewOrder';
    } else if (addingNewAddress) {
      endpoint = 'addAddressAndOrder';
    } else {
      endpoint = 'registerAndOrder';
    }
    callApi(endpoint, 'post', request)
      .then((res) => {
        trackOrder(res.order, res.isFirstOrder, promoCode && promoCode.code);
        this.setCustomerAndOrderId(res.customer, res.order.id);
        navigate(routesMap.Step4.url);
      })
      .catch((response) => {
        this.setState({ popUpWaitTimeIsOpen: false });
        if (typeof Raven !== 'undefined') {
          Raven.captureException(JSON.stringify(response)); // eslint-disable-line
        } else {
          // eslint-disable-next-line no-console
          console.error(response);
        }
      });
  }

  render() {
    const {
      isAlreadyCustomer, isLoggedIn, deliveryFee,
      formInputs, formInputsAlreadyCustomer,
      popUpLocalityIsOpen, popUpWaitTimeIsOpen,
      selectedAddress, addingNewAddress, addressesList,
      isPasswordForgotten, isSubscribingToNewsletter,
    } = this.state;
    const { orderContext: { clothes, slots }, customerContext: { customer } } = this.props;

    const clothesWithCustomerFeedback = clothes.filter(({ selectedCloth, selectedPiece }) => (
      !!customerFeedbacks[selectedCloth] || !!customerFeedbacks[selectedPiece]
    )).map(({ selectedCloth, selectedPiece }) => selectedCloth || selectedPiece);
    const customerFeedback = customerFeedbacks[clothesWithCustomerFeedback[0]];

    const customerHasPhone = !!customer && !!customer.phone;
    const customerHasSubscribedToNewsletter = customer && customer.hasSubscribedToNewsletter;
    const inputList = (isAlreadyCustomer && !isLoggedIn) ? formInputsAlreadyCustomer : formInputs;
    const isDone = this.checkIsDone();
    return (
      <MeetingInfosContainer>
        <OutInSideClick handleClickOutside={() => this.setPopUpIsOpen(false)}>
          <PopUpLocality isDisplayed={popUpLocalityIsOpen}>
            <PopUpTextContainer>
              <PopUpText>
                Oups ! Votre ville n’est pas accessible en métro. Nos couturiers vont devoir se débrouiller autrement.
              </PopUpText>
              Pour les dédommager, les frais de déplacement sont passés à 15 €.
            </PopUpTextContainer>
            <PopUpText pointer onClick={() => this.setPopUpIsOpen(false)}>OK</PopUpText>
          </PopUpLocality>
        </OutInSideClick>
        {popUpWaitTimeIsOpen && (
          <PopUpWaitTimeContainer>
            <PopUpWaitTime isDisplayed>
              <Dots size={9} spacing={22} isAnimated />
              <PopUpText marginTop={25}>Création de la commande en cours</PopUpText>
              <PopUpText>Merci de patienter quelques instants</PopUpText>
            </PopUpWaitTime>
          </PopUpWaitTimeContainer>
        )}
        <Title2>Où se retrouve-t-on ?</Title2>
        <ComponentsContainer>
          <ContactInfos
            isAlreadyCustomer={isAlreadyCustomer}
            isLoggedIn={isLoggedIn}
            addingNewAddress={addingNewAddress}
            addressesList={addressesList}
            selectedAddress={selectedAddress}
            customerHasPhone={customerHasPhone}
            customerHasSubscribedToNewsletter={customerHasSubscribedToNewsletter}
            isSubscribingToNewsletter={isSubscribingToNewsletter}
            toggleSubscribeToNewsletter={() => this.setState({ isSubscribingToNewsletter: !isSubscribingToNewsletter })}
            formInputs={formInputs}
            inputList={inputList}
            setIsAlreadyCustomer={this.setIsAlreadyCustomer}
            login={this.login}
            onSelectAddress={this.onSelectAddress}
            addAddress={this.addAddress}
            checkFormInputValue={this.checkFormInputValue}
            setFormInputValue={this.setFormInputValue}
            setIsAlreadyCustomerWhenEmailChecked={this.setIsAlreadyCustomerWhenEmailChecked}
            showPasswordForgottenModal={() => this.setState({ isPasswordForgotten: true })}
          />

          {isAlreadyCustomer && !isLoggedIn && <Feedback isTransparent feedback={feedbacks[1]} />}

          {(!isAlreadyCustomer || (isAlreadyCustomer && isLoggedIn)) &&
            <OrderSummaryContainer
              vertical
              clothes={clothes}
              selectedDates={slots}
              canEdit
              deliveryFee={deliveryFee}
              hasZipcode={!!formInputs.zipcode.value && !formInputs.zipcode.error}
            />
          }
        </ComponentsContainer>
        {(!isAlreadyCustomer || (isAlreadyCustomer && isLoggedIn)) &&
          <div>
            <ComponentsContainer marginBottom marginTop>
              <LogoButton onClick={() => navigate(routesMap.Step2.url)} back noZoom>Disponibilités</LogoButton>
              {isDone &&
                <StyledLink onClick={this.submitOrder}>
                  <LogoButton
                    backgroundColor={colors.navy} textColor={colors.white}
                    back logo={tick} neverHideLogo mobileFirst
                  >
                    Confirmer le RDV
                  </LogoButton>
                </StyledLink>
              }
              {!isDone &&
                <LogoButton
                  isAvailable={false}
                  backgroundColor={colors.navy} textColor={colors.white}
                  back logo={tick} neverHideLogo mobileFirst
                >
                  Confirmer le RDV
                </LogoButton>
              }
            </ComponentsContainer>

            <ComponentsContainer>
              <Feedback isTransparent feedback={customerFeedback || feedbacks[0]} />
            </ComponentsContainer>
          </div>
        }
        {isPasswordForgotten && (
          <PasswordForgottenModal
            hide={() => this.setState({ isPasswordForgotten: false })}
          />
        )}
      </MeetingInfosContainer>
    );
  }
}

MeetingInfos.propTypes = {
  orderContext: PropTypes.shape({
    clothes: PropTypes.arrayOf(PropTypes.shape({
      selectedFabricOfAccessoriesCreation: PropTypes.arrayOf(PropTypes.string),
    })),
    slots: PropTypes.shape({}),
    setOrderId: PropTypes.func,
    updateDeliveryFee: PropTypes.func,
    rdv3ClicksOrder: PropTypes.shape({
      fabrics: PropTypes.arrayOf(PropTypes.string).isRequired,
      knowHow: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
      suitAlterations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    }).isRequired,
    promoCode: PropTypes.object,
    funnelType: PropTypes.string.isRequired,
  }).isRequired,
  customerContext: PropTypes.shape({
    customer: PropTypes.shape({
      firstname: PropTypes.string,
      lastname: PropTypes.string,
      email: PropTypes.string,
      phone: PropTypes.string,
      address: PropTypes.object,
      addresses: PropTypes.array,
      hasSubscribedToNewsletter: PropTypes.bool,
    }),
    setCustomer: PropTypes.func,
    setCustomerDiscountVouchers: PropTypes.func,
    setSelectedAccountCategory: PropTypes.func,
  }).isRequired,
};

export default withCustomerContext(withOrderContext(MeetingInfos));
