const curtainAdjustments = [
  { label: '< 2m de large', alterationId: '7.deco-rid-1' },
  { label: '2m de large ou plus', alterationId: '7.deco-rid-2' },
];

const curtainAlterations = [
  { label: 'Ajuster la taille à mes fenêtres', adjustments: curtainAdjustments },
  { label: 'Réparer un accroc', alterationId: '7.deco-rid-5' },
  { label: 'Faire un raccord', alterationId: '7.deco-rid-7' },
  { label: 'Ajouter un fil de plomb', alterationId: '7.deco-rid-6' },
];

const cushionAlterations = [
  { label: 'Un accroc', alterationId: '7.deco-cou-5' },
  { label: 'Un zip', alterationId: '7.deco-cou-3' },
];

const embroideries = [
  { label: 'Des initiales' },
  { label: 'Un mot (10 lettres)' },
  { label: 'Un dessin' },
  { label: 'En parler en RDV' },
];

const linensProblems = [
  { label: 'Il est abimé' },
  { label: 'Il est trop grand' },
];

const linensAlterations = [
  {
    id: 0,
    label: 'Il y a un accroc',
    alterationId: '7.deco-mai-14',
    question: { label: "Combien y a-t-il d'accrocs ?", answer: 1 },
  },
  {
    id: 1,
    label: 'Il y manque un bouton',
    alterationId: '7.deco-mai-15',
    question: { label: 'Combien manquent-ils de boutons ?', answer: 1 },
  },
  {
    id: 2,
    label: 'Un zip est bloqué',
    alterationId: '7.deco-mai-10',
    question: { label: 'Combien de zip sont bloqués ?', answer: 1 },
  },
];

const linensSizes = [
  { label: "Moins d'1m",
    widthAdjustmentId: '7.deco-mai-8',
    heightAdjustmentId: '7.deco-mai-7',
    bothAdjustmentId: '7.deco-mai-9',
  },
  { label: "Moins d'1m60",
    widthAdjustmentId: '7.deco-mai-5',
    heightAdjustmentId: '7.deco-mai-4',
    bothAdjustmentId: '7.deco-mai-6',
  },
  { label: "Plus d'1m60",
    widthAdjustmentId: '7.deco-mai-2',
    heightAdjustmentId: '7.deco-mai-1',
    bothAdjustmentId: '7.deco-mai-3',
  },
];

const linensWhere = [
  { label: 'La largeur', slug: 'widthAdjustment' },
  { label: 'La longueur', slug: 'heightAdjustment' },
  { label: 'Les deux', slug: 'bothAdjustment' },
];

const getList = items => items.map(item => ({ isActive: false, ...item }));

const initialState = {
  selectedCategory: undefined,
  selectedCloth: '',
  selectedPiece: '',
  selectedAccessoryCreation: '',
  selectedBagShape: {},
  selectedCreationNumberOfPieces: 1,
  selectedBagSize: {},
  selectedBagHandle: {},
  selectedBagClosing: {},
  selectedProblems: {},
  selectedLining: '',
  numberOfPieces: 1,
  isCurtainLined: undefined,
  activeCurtainAdjustment: -1,
  isCushionSmall: undefined,
  cushionHasZip: undefined,
  isCushionAlterationSelected: false,
  activeEmbroideryFontSize: -1,
  activeEmbroideryDrawingSize: -1,
  isDrawingFull: undefined,
  isDrawingEasy: undefined,
  displayButtons: false,
  selectedFabric: '',
  selectedFabricOfAccessoriesCreation: [],
  selectedTypeOfWork: '',
  isFurSelected: false,
  upcyclingCategories: [],
  upcyclingItems: [],
  suitPieces: [],
  suitProblems: [],
  suitItems: [],
  suitPieceLinings: {},
};

export default function getInitialState(preSelectedStateValues = {}) {
  return {
    ...initialState,
    ...preSelectedStateValues,
    embroideries: getList(embroideries),
    curtainAlterations: getList(curtainAlterations),
    cushionAlterations: getList(cushionAlterations),
    linensProblems: getList(linensProblems),
    linensAlterations: getList(linensAlterations),
    linensSizes: getList(linensSizes),
    linensWhere: getList(linensWhere),
  };
}

export {
  getList, initialState, curtainAdjustments, curtainAlterations, cushionAlterations,
  embroideries, linensProblems, linensAlterations, linensSizes, linensWhere,
};
