import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import PhoneForm from './PhoneForm';
import ContactForm, { OptInText, SubscribeContainer, renderCheckbox } from './ContactForm';
import FacebookButton from './FacebookButton';
import AddressesList from './AddressesList';
import withAppContext from '../../withAppContext';
import {
  mobileThresholdPixels, colors, Button as ButtonV3,
} from '../home/v3/styledComponents';

const ContactInfosContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 552px;
  color: ${colors.navy};
  @media (max-width: ${mobileThresholdPixels}) {
    width: 275px;
  }
`;

const TitleContactInfosContainer = styled.div`
  font-family: Libre Baskerville;
  font-weight: 500;
  line-height: 30px;
  font-size: 24px;
  display:flex;
  margin-bottom: 55px;
  @media (max-width: ${mobileThresholdPixels}) {
    line-height: 20px;
    font-size: 18px;
    margin-bottom: ${props => props.mobileMarginBottom ? '60' : '30'}px;
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
`;

const TitleContactInfos = styled.p`
  color: ${props => props.isActive ? colors.navy : colors.lightGrey3};
  margin: 0px 9px 0px 0px;
  cursor: pointer;
  @media (max-width: ${mobileThresholdPixels}) {
    ${props => !props.isActive && 'font-size: 14px; margin-top: 8px;'}
    ${props => props.isActive && 'margin-top: 30px;'}
    margin-right: 0px;
  }
`;

const Label = styled.p`
  font-family: Roboto;
  font-size: 18px;
  margin-bottom: ${props => !props.notLabelForm ? '5' : '40'}px;
  margin-top: ${props => !props.notLabelForm ? '0' : '-35'}px;
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 14px;
    ${props => props.notLabelForm && 'margin-top: 0px; margin-bottom: 20px;'};
  }
`;

const Label2 = styled(Label)`
  margin-bottom: ${props => !props.notLabelForm ? '5' : '22'}px;
`;

const OrLinesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-top: 5px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin-top: 2px;
    width: 98%;
  }
`;

const Line = styled.hr`
  width: 168px;
  height: 0.75px;
  border: 0px;
  background-color: ${colors.navy};

  @media (max-width: ${mobileThresholdPixels}) {
    width: 120px;
  }
`;

const Or = styled.p`
  width: 48px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  text-align: center;
  color: ${colors.navy};

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 12px;
    width: 35px;
  }
`;

const AddressesContainer = styled.div`
  width: inherit;
`;

const PhoneMissingContainer = styled.div`
  margin-top: 30px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const Button = styled(ButtonV3)`
  margin-top: ${props => props.marginTop ? '60px' : '40px'};
  width: 245px;
  font-weight: 500;
  ${props => props.fontSize ? `font-size: ${props.fontSize}px;` : ''}
  @media (max-width: ${mobileThresholdPixels}) {
    width: 200px;
    font-size: 13px;
    align-self: center;
  }
`;

const LabelContainer = styled.div`
  @media (max-width: ${mobileThresholdPixels}) {
    margin-bottom: 10px;
  }
`;

const ContactInfos = ({
  context: { isMobile }, isAlreadyCustomer, isLoggedIn, addingNewAddress, addressesList, selectedAddress,
  customerHasPhone, formInputs, inputList,
  setIsAlreadyCustomer, login, onSelectAddress, addAddress,
  checkFormInputValue, setFormInputValue, setIsAlreadyCustomerWhenEmailChecked,
  showPasswordForgottenModal, customerHasSubscribedToNewsletter, isSubscribingToNewsletter, toggleSubscribeToNewsletter,
}) => (
  <ContactInfosContainer>
    <TitleContactInfosContainer>
      {(!isMobile || (isMobile && !isAlreadyCustomer)) &&
        <TitleContactInfos onClick={() => setIsAlreadyCustomer(false)} isActive={!isAlreadyCustomer}>
          Vos coordonnées
        </TitleContactInfos>
      }

      <TitleContactInfos onClick={() => setIsAlreadyCustomer(true)} isActive={isAlreadyCustomer}>
        {`${!isMobile ? '|' : ''} Déjà client ?${(isMobile && !isAlreadyCustomer) ? ' Se connecter' : ''}`}
      </TitleContactInfos>
    </TitleContactInfosContainer>

    {isAlreadyCustomer && !isLoggedIn &&
      <Label2 notLabelForm>Ravis de vous revoir !</Label2>
    }

    {!isLoggedIn &&
    <div>
      <FacebookButton
        label={!isAlreadyCustomer ? 'S’inscrire avec Facebook' : 'Se connecter avec Facebook'}
        loginWithFacebook={({ accessToken }) => login({ accessToken })}
      />
      <OrLinesContainer><Line /><Or>ou</Or><Line /></OrLinesContainer>
    </div>
    }

    {isAlreadyCustomer && isLoggedIn &&
      <LabelContainer>
        <Label notLabelForm>
          Hello {inputList.firstname.value} ! Comment allez-vous depuis la dernière fois&nbsp;?
        </Label>
        <Label notLabelForm>{addingNewAddress ?
          'Enregistrez une nouvelle adresse :'
          : 'Sélectionnez votre adresse :'}
        </Label>
      </LabelContainer>
    }
    {isAlreadyCustomer && isLoggedIn
      && addressesList.length > 0 && !addingNewAddress
      ? (
        <div>
          <AddressesContainer>
            <AddressesList
              addresses={addressesList}
              selectedAddress={selectedAddress}
              onSelectAddress={address => onSelectAddress(address)}
            />
            <ButtonContainer>
              <Button
                navy
                marginTop
                fontSize={14}
                onClick={addAddress}
              >
                Ajouter une adresse
              </Button>
            </ButtonContainer>
          </AddressesContainer>
          {!customerHasPhone && formInputs.phone &&
            <PhoneMissingContainer>
              <Label>Enregistrez un numéro de téléphone</Label>
              <PhoneForm
                inputPhone={formInputs.phone}
                addingNewAddress={addingNewAddress}
                checkFormInputValue={checkFormInputValue}
                setFormInputValue={setFormInputValue}
              />
            </PhoneMissingContainer>
          }
          {!customerHasSubscribedToNewsletter && (
            <SubscribeContainer hasMarginTop>
              {renderCheckbox(isSubscribingToNewsletter, toggleSubscribeToNewsletter)}
              <OptInText>
                J’accepte de recevoir des mails d’informations commerciales
              </OptInText>
            </SubscribeContainer>
          )}
        </div>
      ) : (
        <ContactForm
          inputList={inputList}
          addingNewAddress={addingNewAddress}
          checkFormInputValue={checkFormInputValue}
          setFormInputValue={setFormInputValue}
          setIsAlreadyCustomerWhenEmailChecked={setIsAlreadyCustomerWhenEmailChecked}
          isAlreadyCustomer={isAlreadyCustomer}
          isLoggedIn={isLoggedIn}
          customerHasPhone={customerHasPhone}
          showPasswordForgottenModal={showPasswordForgottenModal}
          customerHasSubscribedToNewsletter={customerHasSubscribedToNewsletter}
          isSubscribingToNewsletter={isSubscribingToNewsletter}
          toggleSubscribeToNewsletter={toggleSubscribeToNewsletter}
        />
      )
    }

    {isAlreadyCustomer && !isLoggedIn && <Button navy onClick={login}>Se connecter</Button>}

    {isAlreadyCustomer && !isLoggedIn && isMobile &&
      <TitleContactInfosContainer mobileMarginBottom>
        <TitleContactInfos onClick={() => setIsAlreadyCustomer(false)} isActive={!isAlreadyCustomer}>
          Pas encore inscrit ?
        </TitleContactInfos>
      </TitleContactInfosContainer>
    }
  </ContactInfosContainer>
);

ContactInfos.propTypes = {
  context: PropTypes.shape({
    isMobile: PropTypes.bool,
  }).isRequired,
  isAlreadyCustomer: PropTypes.bool.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  addingNewAddress: PropTypes.bool.isRequired,
  addressesList: PropTypes.arrayOf(PropTypes.shape({})),
  selectedAddress: PropTypes.shape({}),
  customerHasPhone: PropTypes.bool,
  customerHasSubscribedToNewsletter: PropTypes.bool,
  formInputs: PropTypes.shape({}).isRequired,
  inputList: PropTypes.shape({}).isRequired,
  setIsAlreadyCustomer: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
  onSelectAddress: PropTypes.func.isRequired,
  addAddress: PropTypes.func.isRequired,
  checkFormInputValue: PropTypes.func.isRequired,
  setFormInputValue: PropTypes.func.isRequired,
  setIsAlreadyCustomerWhenEmailChecked: PropTypes.func.isRequired,
  showPasswordForgottenModal: PropTypes.func.isRequired,
  isSubscribingToNewsletter: PropTypes.bool.isRequired,
  toggleSubscribeToNewsletter: PropTypes.func.isRequired,
};

ContactInfos.defaultProps = {
  addressesList: [],
  selectedAddress: {},
  customerHasPhone: false,
  customerHasSubscribedToNewsletter: false,
};

export default withAppContext(ContactInfos);
