import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { mobileThresholdPixels, colors } from '../home/v3/styledComponents';
import AddressBullet from './AddressBullet';

const AddressContainer = styled.li`
    display: flex;
    flex-direction: row;
    justify-content: start;
    cursor: pointer;
`;

const TextContainer = styled.div`
  padding-top: 5px;
  padding-bottom: ${props => props.noMargin ? '0px' : '40px'};
  @media (max-width: ${mobileThresholdPixels}) {
    padding-bottom: ${props => props.noMargin ? '0px' : '20px'};
    }
  }
`;

const Text = styled.p`
  font-size: 18px;
  margin: 0px;
  margin-bottom: ${props => props.noMarginBottom ? '0px' : '14px'}; 
  color: ${colors.navy};
  ${({ bold }) => bold && 'font-weight: 500;'}
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 14px;
    line-height: 20px;
    margin: 0px;
  }
`;

const AddressesList = ({ addresses, onSelectAddress, selectedAddress }) => (
  <div>
    {addresses
      .filter(address => address && address.street)
      .map((address, index) => (
        <AddressContainer
          key={address._id}
          onClick={() => onSelectAddress(address)}
        >
          <AddressBullet isActive={selectedAddress._id === address._id} />
          <TextContainer noMargin={addresses.length - 1 === index} >
            <Text bold={!!address.name} noMarginBottom={!address.name}>
              {!!address.name && address.name}
            </Text>
            <Text>{address.street}</Text>
            {!!address.comment && <Text>{address.comment}</Text>}
            <Text>{address.zipcode} {address.locality}</Text>
          </TextContainer>
        </AddressContainer>
      ))}
  </div>
);

AddressesList.propTypes = {
  addresses: PropTypes.arrayOf(PropTypes.shape({})),
  onSelectAddress: PropTypes.func,
  selectedAddress: PropTypes.shape({}),
};

AddressesList.defaultProps = {
  addresses: [],
  onSelectAddress() {},
  selectedAddress: {},
};

export default AddressesList;
