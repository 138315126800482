import moment from 'moment-timezone';
import momentFr from '../config/moment.fr';

moment.updateLocale('fr', momentFr);
moment.tz.setDefault('Europe/Paris');

export function getAlterationsFromProblems(treeInverse, pricingInverse, { clothSlug, problems, clothType }) {
  if (!problems) return [];
  const alterations = [];
  Object.values(problems)
    .forEach((locations, index) => {
      if (typeof locations[0] === 'string') {
        locations.forEach((locationSlug) => {
          const location = treeInverse[`${clothSlug}_${locationSlug}`];
          if (clothSlug === 'cost') {
            alterations.push(pricingInverse[location.id]);
          } else if (clothType === 'COMPLEX') {
            alterations.push(pricingInverse[location.complexAlterationId]);
          } else {
            alterations.push(pricingInverse[location.simpleAlterationId]);
          }
          if (problems[`${locationSlug}_multiple`]) {
            alterations[alterations.length - 1].quantity = problems[`${locationSlug}_multiple`][0].value;
          }
        });
      } else if (Object.keys(problems)[index] === 'adjustment_acce_multiple') {
        if (clothType === 'COMPLEX') {
          alterations.push(pricingInverse['7.acce-div-18']);
        } else {
          alterations.push(pricingInverse['7.acce-div-17']);
        }
        alterations[alterations.length - 1].quantity = problems.adjustment_acce_multiple[0].value;
      }
    });
  return alterations;
}

function findEmbroideryAlteration(pricingInverse, clothSlug, clothType, name) {
  let updatedClothSlug = clothSlug;
  if (clothSlug === 'mail') updatedClothSlug = 'ling';
  if (clothSlug === 'cost') updatedClothSlug = 'pant';

  return Object.values(pricingInverse).find(item =>
    item.id.includes(`${updatedClothSlug}-bro`) &&
    (!item.type || item.type === clothType) &&
    item.name.includes(name),
  ).id;
}

export function getAlterationsFromEmbroideries(pricingInverse, { clothSlug, embroideries,
  isDrawingEasy, isDrawingFull, activeEmbroideryDrawingSize, activeEmbroideryFontSize,
  clothType, numberOfPieces }) {
  if (!embroideries) return [];
  const alterations = [];

  if (embroideries[0].isActive) {
    alterations.push(findEmbroideryAlteration(pricingInverse, clothSlug, clothType, 'Initiales'));
  }

  if (embroideries[1].isActive) {
    if (activeEmbroideryFontSize === 0) {
      alterations.push(findEmbroideryAlteration(pricingInverse, clothSlug, clothType,
        'Un mot (10 lettres - petite police)'));
    } else if (activeEmbroideryFontSize === 1) {
      alterations.push(findEmbroideryAlteration(pricingInverse, clothSlug, clothType,
        'Un mot (10 lettres - grosse police)'));
    } else {
      alterations.push(findEmbroideryAlteration(pricingInverse, clothSlug, clothType,
        'Un mot (10 lettres - grosse police pleine)'));
    }
  }

  if (embroideries[2].isActive) {
    if (isDrawingFull) {
      if (isDrawingEasy) {
        if (activeEmbroideryDrawingSize === 0) {
          alterations.push(findEmbroideryAlteration(pricingInverse, clothSlug, clothType,
            'Dessin plein forme simple < 5cm'));
        } else {
          alterations.push(findEmbroideryAlteration(pricingInverse, clothSlug, clothType,
            'Dessin plein forme simple < 12cm'));
        }

      // complex full drawing
      } else if (activeEmbroideryDrawingSize === 0) {
        alterations.push(findEmbroideryAlteration(pricingInverse, clothSlug, clothType,
          'Dessin plein complexe < 5cm'));
      } else {
        alterations.push(findEmbroideryAlteration(pricingInverse, clothSlug, clothType,
          'Dessin plein complexe < 12cm'));
      }

    // hollow easy drawing
    } else if (isDrawingEasy) {
      if (activeEmbroideryDrawingSize === 0) {
        alterations.push(findEmbroideryAlteration(pricingInverse, clothSlug, clothType,
          'Dessin creux forme simple < 5cm'));
      } else if (activeEmbroideryDrawingSize === 1) {
        alterations.push(findEmbroideryAlteration(pricingInverse, clothSlug, clothType,
          'Dessin creux forme simple < 12cm'));
      } else if (activeEmbroideryDrawingSize === 2) {
        alterations.push(findEmbroideryAlteration(pricingInverse, clothSlug, clothType,
          'Dessin creux forme simple < 25cm'));
      }

    // hollow complex drawing
    } else if (activeEmbroideryDrawingSize === 0) {
      alterations.push(findEmbroideryAlteration(pricingInverse, clothSlug, clothType,
        'Dessin creux complexe < 5cm'));
    } else if (activeEmbroideryDrawingSize === 1) {
      alterations.push(findEmbroideryAlteration(pricingInverse, clothSlug, clothType,
        'Dessin creux complexe < 12cm'));
    } else if (activeEmbroideryDrawingSize === 2) {
      alterations.push(findEmbroideryAlteration(pricingInverse, clothSlug, clothType,
        'Dessin creux complexe < 25cm'));
    }
  }

  return alterations.map(alterationId => ({
    ...pricingInverse[alterationId],
    quantity: numberOfPieces || 1,
  }));
}

export function getTypeForBagCreation(bagShape = {}, bagSize = {}) {
  return `${bagShape.slug}_${bagSize.slug}`;
}

export function getAlterationsFromCreations(pricingInverse, {
  bagShape, bagSize, bagHandle, bagClosing, creationNumberOfPieces = 1, otherItems = [],
}) {
  const alterations = [];

  let newOtherItems = [...otherItems];

  const type = getTypeForBagCreation(bagShape, bagSize);
  const bagSizeAlteration = bagSize?.alterations?.[type] && pricingInverse[bagSize.alterations[type]];
  if (bagSizeAlteration) alterations.push({ ...bagSizeAlteration, quantity: creationNumberOfPieces });
  const bagHandleAlteration = bagHandle?.alterations?.[type] && pricingInverse[bagHandle.alterations[type]];
  if (bagHandleAlteration) alterations.push({ ...bagHandleAlteration, quantity: creationNumberOfPieces });
  const bagClosingAlteration = bagClosing?.alterations?.[type] && pricingInverse[bagClosing.alterations[type]];
  if (bagClosingAlteration) {
    alterations
      .push({
        ...bagClosingAlteration,
        quantity: creationNumberOfPieces + (newOtherItems.includes(bagClosingAlteration.id) ? 1 : 0),
      });
    if (newOtherItems.includes(bagClosingAlteration.id)) {
      newOtherItems = newOtherItems
        .filter(otherItem => otherItem !== bagClosingAlteration.id);
    }
  }

  if (newOtherItems) {
    newOtherItems.forEach((otherItem) => {
      alterations.push(pricingInverse[otherItem]);
    });
  }

  return alterations;
}

const getUpcyclingItemsFromPricingInverse = (upcyclingPricingInverse, upcyclingItems) => {
  const newUpcyclingItems = [];
  upcyclingItems.forEach(({ id, quantity }) => {
    newUpcyclingItems.push(upcyclingPricingInverse[id]);
    if (quantity) newUpcyclingItems[newUpcyclingItems.length - 1].quantity = quantity.value;
  });
  return newUpcyclingItems;
};

const getFormattedUpcyclingItems = (upcyclingItems, clothType) => (
  upcyclingItems.map(({ name, id, quantity, prices, liningPrices, hasPriceRange }) => {
    const price = prices[clothType];
    return ({
      id,
      name,
      quantity,
      hasPriceRange,
      price: hasPriceRange ? ((price.max + price.min) / 2) : price,
      priceRange: hasPriceRange && (price.max - price.min),
      liningUnitPrice: liningPrices?.[clothType] || 0,
    });
  })
);

const getUpcyclingItems = (upcyclingPricingInverse, upcyclingItems, clothType) => {
  const newUpcyclingItems = getUpcyclingItemsFromPricingInverse(upcyclingPricingInverse, upcyclingItems);
  return getFormattedUpcyclingItems(newUpcyclingItems, clothType);
};

export function getAlterationsForCloth(treeInverse, pricingInverse,
  {
    typeOfWork, clothSlug, problems, clothType,
    embroideries, isDrawingEasy, isDrawingFull, activeEmbroideryDrawingSize, activeEmbroideryFontSize,
    accessoryCreation, bagShape, bagSize, bagHandle, bagClosing,
    creationNumberOfPieces, otherItems,
  }) {
  if (typeOfWork === 'alteration') {
    return getAlterationsFromProblems(
      treeInverse, pricingInverse, { clothSlug, problems, clothType });
  }
  if (typeOfWork === 'embroidery') {
    return getAlterationsFromEmbroideries(
      pricingInverse, { clothSlug,
        embroideries,
        isDrawingEasy,
        isDrawingFull,
        activeEmbroideryDrawingSize,
        activeEmbroideryFontSize,
        clothType });
  }
  if (typeOfWork === 'creation') {
    return getAlterationsFromCreations(
      pricingInverse, {
        clothType,
        accessoryCreation,
        bagShape,
        bagSize,
        bagHandle,
        bagClosing,
        creationNumberOfPieces,
        otherItems,
      },
    );
  }
  return [];
}

export const getClothType = (clothSlug, fabric) => (!fabric
    || ['mant', 'deco'].includes(clothSlug)
    || ['other', 'jeans'].includes(fabric))
  ? 'SIMPLE'
  : 'COMPLEX';

export const getClothName = (tree, clothSlug, suitPiece) => {
  let clothName = '';
  const treeCloth = tree.find(cloth => cloth.slug === clothSlug);
  if (suitPiece) {
    clothName = treeCloth.pieces[suitPiece];
  } else {
    clothName = treeCloth?.name || '';
  }
  return clothName;
};

export const formatCloth = (tree, treeInverse, pricingInverse, upcyclingPricingInverse,
  { selectedCloth, selectedLining, isCurtainLined, selectedProblems,
    selectedFabric, selectedTypeOfWork, embroideries, isDrawingEasy,
    isDrawingFull, activeEmbroideryDrawingSize, activeEmbroideryFontSize,
    selectedAccessoryCreation, selectedBagShape, selectedBagSize,
    selectedBagHandle, selectedBagClosing, selectedCreationNumberOfPieces,
    otherItems, selectedFabricOfAccessoriesCreation, upcyclingItems, suitPiece, index,
  },
  brand,
) => ({
  index,
  slug: selectedTypeOfWork === 'creation' ? 'acce' : selectedCloth,
  name: selectedTypeOfWork === 'creation'
    ? `Accessoires (${getClothName(tree, selectedCloth)})`.replace('(Accessoires)', '')
    : getClothName(tree, selectedCloth, suitPiece),
  suitPiece,
  liningsNumber: (selectedLining === 'haveLining' || isCurtainLined) ? 1 : 0,
  selectedFabricOfAccessoriesCreation,
  fabric: selectedFabric,
  typesOfWork: [selectedTypeOfWork],
  clothType: getClothType(selectedCloth, selectedFabric),
  brand,
  hasTalkRDV: (selectedProblems && Object.keys(selectedProblems).includes('talkRDV')) || (embroideries && embroideries[3].isActive),
  upcyclingItems: (selectedTypeOfWork !== 'transformation' || upcyclingItems.length < 1)
    ? [] : getUpcyclingItems(upcyclingPricingInverse, upcyclingItems, getClothType(selectedCloth, selectedFabric)),
  items: getAlterationsForCloth(
    treeInverse, pricingInverse,
    { typeOfWork: selectedTypeOfWork,
      clothSlug: selectedCloth,
      problems: selectedProblems,
      clothType: getClothType(selectedCloth, selectedFabric),
      embroideries,
      isDrawingEasy,
      isDrawingFull,
      activeEmbroideryDrawingSize,
      activeEmbroideryFontSize,
      accessoryCreation: selectedAccessoryCreation,
      bagShape: selectedBagShape,
      bagSize: selectedBagSize,
      bagHandle: selectedBagHandle,
      bagClosing: selectedBagClosing,
      creationNumberOfPieces: selectedCreationNumberOfPieces,
      otherItems,
    },
  )
    .map(alteration => ({
      id: alteration.id,
      name: alteration.name,
      quantity: alteration.quantity || 1,
      price: alteration.price,
      liningUnitPrice: alteration.liningUnitPrice,
    })),
});


function getDecoPieceName(piece, numberOfPieces) {
  if (piece === 'ride') return (numberOfPieces > 1) ? 'Rideaux' : 'Rideau';
  if (piece === 'cous') return (numberOfPieces > 1) ? 'Housses' : 'Housse';
  if (piece === 'mais') return 'Linge de maison';
  return '';
}

function getLiningLabel(isCurtainLined, numberOfPieces) {
  if (!isCurtainLined) return '';
  return (numberOfPieces > 1) ? ' doublés' : ' doublé';
}

export function getDecoName(piece, numberOfPieces, isCurtainLined) {
  const pieceName = getDecoPieceName(piece, numberOfPieces);
  const liningLabel = getLiningLabel(isCurtainLined, numberOfPieces);
  const quantityLabel = (numberOfPieces > 1 && piece !== 'mais') ? ` (x${numberOfPieces})` : '';
  return `${pieceName}${liningLabel}${quantityLabel}`;
}

function getAlterationsForCurtain(pricingInverse, {
  curtainAlterations = [], activeCurtainAdjustment,
}) {
  return curtainAlterations
    .filter(alteration => alteration.isActive)
    .filter(alteration => alteration.alterationId || alteration.adjustments)
    .map(alteration => alteration.alterationId
      ? pricingInverse[alteration.alterationId]
      : pricingInverse[alteration.adjustments[activeCurtainAdjustment].alterationId],
    );
}

function getAlterationsForCushion(pricingInverse, { cushionAlterations }) {
  const alterations = [];
  cushionAlterations.forEach((cushionAlteration) => {
    if (cushionAlteration.isActive) {
      alterations.push(pricingInverse[cushionAlteration.alterationId]);
    }
  });
  return alterations;
}

function getAlterationsForLinens(pricingInverse, {
  linensProblems, linensAlterations, linensSizes, linensWhere,
}) {
  if (!linensProblems[0].isActive && !linensProblems[1].isActive) return [];
  const alterations = [];
  if (linensProblems[0].isActive) {
    linensAlterations
      .filter(({ isActive }) => isActive)
      .forEach(({ alterationId, question: { answer } }) => {
        alterations.push(pricingInverse[alterationId]);
        alterations[alterations.length - 1].quantity = answer;
      });
  }
  if (linensProblems[1].isActive) {
    const size = linensSizes.filter(({ isActive }) => isActive)[0];
    const where = linensWhere.filter(({ isActive }) => isActive)[0];

    alterations.push(pricingInverse[size[`${where.slug}Id`]]);
  }
  return alterations;
}

function getAlterationsForDeco(pricingInverse, {
  piece, curtainAlterations, activeCurtainAdjustment, cushionAlterations,
  embroideries, isDrawingEasy, isDrawingFull, activeEmbroideryDrawingSize, activeEmbroideryFontSize,
  linensProblems, linensAlterations, linensSizes, linensWhere, typeOfWork,
  accessoryCreation, bagShape, bagSize, bagHandle, bagClosing,
  creationNumberOfPieces,
}) {
  if (typeOfWork === 'alteration') {
    if (piece === 'ride') {
      return getAlterationsForCurtain(
        pricingInverse,
        {
          curtainAlterations,
          activeCurtainAdjustment,
        },
      );
    }
    if (piece === 'cous') {
      return getAlterationsForCushion(
        pricingInverse,
        { cushionAlterations },
      );
    }
    if (piece === 'mais') {
      return getAlterationsForLinens(
        pricingInverse,
        {
          linensProblems,
          linensAlterations,
          linensSizes,
          linensWhere,
        },
      );
    }
  }
  if (typeOfWork === 'embroidery') {
    return getAlterationsFromEmbroideries(
      pricingInverse, {
        clothSlug: 'deco',
        embroideries,
        isDrawingEasy,
        isDrawingFull,
        activeEmbroideryDrawingSize,
        activeEmbroideryFontSize,
        clothType: 'SIMPLE',
      });
  }
  if (typeOfWork === 'creation') {
    return getAlterationsFromCreations(
      pricingInverse, {
        clothType: 'SIMPLE',
        accessoryCreation,
        bagShape,
        bagSize,
        bagHandle,
        bagClosing,
        creationNumberOfPieces,
      },
    );
  }
  return [];
}

export const formatPiece = (pricingInverse,
  { selectedCategory, selectedPiece, isCurtainLined, curtainAlterations, numberOfPieces,
    activeCurtainAdjustment, isCushionSmall, cushionHasZip, cushionAlterations, embroideries,
    isDrawingEasy, isDrawingFull, activeEmbroideryDrawingSize, activeEmbroideryFontSize,
    linensProblems, linensAlterations, linensSizes, linensWhere, selectedTypeOfWork,
    selectedAccessoryCreation, selectedBagShape, selectedBagSize, selectedBagHandle,
    selectedBagClosing, selectedCreationNumberOfPieces, otherItems, selectedFabricOfAccessoriesCreation,
  },
  brand,
) => ({
  slug: selectedTypeOfWork === 'creation' ? 'acce' : selectedCategory,
  name: selectedTypeOfWork === 'creation'
    ? `Accessoires (${getDecoName(selectedPiece, numberOfPieces, isCurtainLined, curtainAlterations)})`
    : getDecoName(selectedPiece, numberOfPieces, isCurtainLined, curtainAlterations),
  liningsNumber: isCurtainLined ? 1 : 0,
  selectedFabricOfAccessoriesCreation,
  typesOfWork: [selectedTypeOfWork],
  clothType: 'SIMPLE',
  brand,
  hasTalkRDV: embroideries && embroideries[3].isActive,
  items: getAlterationsForDeco(pricingInverse,
    { piece: selectedPiece,
      isCurtainLined,
      curtainAlterations,
      numberOfPieces,
      activeCurtainAdjustment,
      isCushionSmall,
      cushionHasZip,
      cushionAlterations,
      embroideries,
      isDrawingEasy,
      isDrawingFull,
      activeEmbroideryDrawingSize,
      activeEmbroideryFontSize,
      linensProblems,
      linensAlterations,
      linensSizes,
      linensWhere,
      typeOfWork: selectedTypeOfWork,
      accessoryCreation: selectedAccessoryCreation,
      bagShape: selectedBagShape,
      bagSize: selectedBagSize,
      bagHandle: selectedBagHandle,
      bagClosing: selectedBagClosing,
      creationNumberOfPieces: selectedCreationNumberOfPieces,
      otherItems,
    })
    .map(alteration => ({
      id: alteration.id,
      name: alteration.name,
      quantity: alteration.quantity || numberOfPieces,
      price: alteration.price,
      liningUnitPrice: alteration.liningUnitPrice,
    })),
});

export function formatClothOrPiece(tree, treeInverse, pricingInverse, upcyclingPricingInverse, clothOrPiece, brand) {
  if (clothOrPiece.selectedCategory === 'deco') return formatPiece(pricingInverse, clothOrPiece, brand);
  return formatCloth(tree, treeInverse, pricingInverse, upcyclingPricingInverse, clothOrPiece, brand);
}

export function formatAllDaySlots(selectedDate) {
  const slots = [];
  const { hours, minutes } = selectedDate.slotBlocks[0].slots[0];
  const begin = moment(selectedDate.date).set({ hours, minutes, seconds: 0, milliseconds: 0 });

  const lastSlotBlock = selectedDate.slotBlocks[selectedDate.slotBlocks.length - 1];
  const slotsLength = lastSlotBlock.slots.length;
  const { hoursEnd, minutesEnd } = lastSlotBlock.slots[slotsLength - 1];
  const end = moment(selectedDate.date).set({
    hours: hoursEnd, minutes: minutesEnd, seconds: 0, milliseconds: 0,
  });

  const current = moment(begin);

  while (!current.isSameOrAfter(end, 'minutes')) {
    slots.push({
      begin: current.toDate(),
      end: moment(current).add(30, 'minutes').toDate(),
    });
    current.add(30, 'minutes');
  }

  return slots;
}

export function formatBlockSlots(date, slotBlock) {
  const slots = [];
  const { hours, minutes } = slotBlock.slots[0];
  const begin = moment(date).set({ hours, minutes, seconds: 0, milliseconds: 0 });

  const slotsLength = slotBlock.slots.length - 1;
  const { hoursEnd, minutesEnd } = slotBlock.slots[slotsLength];
  const end = moment(date).set({ hours: hoursEnd, minutes: minutesEnd, seconds: 0, milliseconds: 0 });

  const current = moment(begin);

  while (!current.isSameOrAfter(end, 'minutes')) {
    slots.push({
      begin: current.toDate(),
      end: moment(current).add(30, 'minutes').toDate(),
    });
    current.add(30, 'minutes');
  }

  return slots;
}

export function formatSlot(date, slot) {
  const { hours, minutes } = slot;
  const begin = moment(date).set({ hours, minutes, seconds: 0, milliseconds: 0 }).toDate();

  const { hoursEnd, minutesEnd } = slot;
  const end = moment(date).set({
    hours: hoursEnd, minutes: minutesEnd, seconds: 0, milliseconds: 0 }).toDate();
  return { begin, end };
}

export function formatSlots(selectedDates) {
  const slots = [];
  Object.values(selectedDates).forEach((selectedDate) => {
    if (selectedDate.allDay.isActive) {
      slots.push(...formatAllDaySlots(selectedDate));
    } else {
      selectedDate.slotBlocks.forEach((slotBlock) => {
        if (slotBlock.isActive) {
          slots.push(...formatBlockSlots(selectedDate.date, slotBlock));
        } else {
          slotBlock.slots.filter(slot => slot.isActive).forEach((slot) => {
            slots.push(formatSlot(selectedDate.date, slot));
          });
        }
      });
    }
  });
  return slots;
}
