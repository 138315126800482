import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { formInputWidths } from './meetingInfosFormInputs';
import {
  colors, mobileThresholdPixels, FormInput as FormInputV2,
} from '../home/v3/styledComponents';
import errorLogo from '../../assets/error.png';
import verifiedLogo from '../../assets/verified.png';
import iconCheckCheckbox from '../../assets/check-checkbox.svg';

const FormContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;

const FormInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  color: ${colors.navy};
`;

const InputAndImgContainer = styled.div`
  display: flex;
`;

const FormInput = styled(FormInputV2)`
  margin: 0px ${({ error }) => error !== undefined ? '10' : '20'}px 20px 0px;
  border-radius: 2px;
  padding-top: 0px;
  padding-bottom: 0px;
  height: 38px;
  font-family: Roboto;
  font-size: 15px;
  outline: 0;
  border: solid 0.5px ${colors.navy};
  width: ${props => `${formInputWidths[props.size].desktop}`}px;
  color: ${colors.navy};
  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 100px hsl(1, 100%, 100%) inset;
    -webkit-text-fill-color: ${colors.navy};
  };
  @media (max-width: ${mobileThresholdPixels}) {
    ${props => (props.isMobileLarge || props.size !== 'small') && 'margin-right: 0px;'}
    width: ${props => props.isMobileLarge
    ? formInputWidths.large.mobile
    : formInputWidths[props.size].mobile}px;
    background-position-x: ${props => props.isMobileLarge
    ? formInputWidths.large.mobile - 8
    : formInputWidths[props.size].mobile - 8}px;
  }
`;

const MoreInfos = styled.p`
  ${props => props.noDisplay && 'display: none;'}
  font-family: Roboto;
  line-height: 20px;
  font-size: 14px;
  width: 373px;
  margin-top: -10px;
  margin-bottom: 20px;
  ${props => props.error && `
    color: ${colors.red};
    width: 145px;
    margin-top: ${props.errorOn2Lines ? 26 : 36}px;
    margin-left: ${props.isPhone ? -200 : -6}px;
  `}
  ${props => props.topInfo && `
    width: auto;
    margin: -21px 0px 14px;
  `}
  ${({ small }) => small && 'width: 100px;'}
  @media (max-width: ${mobileThresholdPixels}) {
    width: 258px;
    line-height: 17px;
    font-size: 12px;
    ${props => props.error && `
      width: ${props.errorOn2Lines ? '200px' : '135px'};
      margin-top: -15px;
      margin-left: 0px;
    `}
    ${props => props.topInfo && `
      width: auto;
      margin-top: -5px;
    `}
  }
`;

const I = styled.i`
  background-image: ${({ error }) => error ? `url(${errorLogo})` : `url(${verifiedLogo})`};
  background-size: contain;
  width: 10px;
  height: 10px;
  position: relative;
  left: -30px;
  top: 15px;
`;

const Label = styled.p`
  font-family: Roboto;
  font-size: 18px;
  margin-bottom: ${props => !props.notLabelForm ? '5' : '40'}px;
  margin-top: ${props => !props.notLabelForm ? '0' : '-35'}px;
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 14px;
    ${props => props.notLabelForm && 'margin-top: 0px; margin-bottom: 20px;'};
  }
`;

const UnderlinedSpan = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

const TextLink = styled.p`
  cursor: pointer;
  font-size: 12px;
  line-height: 24px;
  color: ${colors.lightGrey4};
  margin: -15px 0px 0px;
  align-self: flex-start;
  text-decoration: underline;
  width: 100%;
  @media (max-width: ${mobileThresholdPixels}) {
    ${({ left }) => left && `
      margin: 4px 0px 20px;
      width: 275px;
      align-self: center;
    `}
  }
`;

export const OptInText = styled.span`
  font-family: Roboto;
  line-height: 20px;
  font-size: 14px;
  width: 373px;
  margin-bottom: 20px;
  @media (max-width: ${mobileThresholdPixels}) {
    width: 258px;
    line-height: 17px;
    font-size: 12px;
  }
`;

const ImgCheck = styled.img`
  position: absolute;
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 11px;
  height: 11px;
  background: ${({ checked }) => checked ? colors.navy : colors.white};
  border: 1px solid ${({ checked }) => checked ? colors.navy : colors.lightGrey2};
  border-radius: 2px;
  transition: all 150ms;
  cursor: pointer;
  ${ImgCheck} {
    visibility: ${({ checked }) => checked ? 'visible' : 'hidden'}
  }
`;

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-right: 11px;
  margin-top: 4px;
  @media (max-width: ${mobileThresholdPixels}) {
    margin-top: 2px;
  }
`;

export const SubscribeContainer = styled.div`
  display: flex;
  width: 100%;
  ${({ hasMarginTop }) => hasMarginTop && 'margin-top: 20px;'}
  @media (max-width: ${mobileThresholdPixels}) {
    ${({ hasMarginTop }) => hasMarginTop && 'margin-top: 10px;'}
  }
`;

export const renderCheckbox = (checked, onClick) => (
  <CheckboxContainer>
    <HiddenCheckbox value={checked} />
    <StyledCheckbox checked={checked} onClick={onClick}>
      <ImgCheck src={iconCheckCheckbox} alt="sélectionné" />
    </StyledCheckbox>
  </CheckboxContainer>
);

const ContactForm = ({
  inputList, addingNewAddress, checkFormInputValue, setFormInputValue,
  isAlreadyCustomer, isLoggedIn, customerHasPhone, setIsAlreadyCustomerWhenEmailChecked,
  showPasswordForgottenModal, customerHasSubscribedToNewsletter,
  isSubscribingToNewsletter, toggleSubscribeToNewsletter,
}) => (
  <FormContainer>
    {Object.values(inputList).map((formInput, index) => {
      if (formInput.name.includes('error')) {
        return (
          <FormInputContainer key={formInput.label}>
            {!formInput.withDefaultProps ?
              <MoreInfos
                error
                errorOn2Lines={formInput.label.length > 20}
                isPhone={formInput.name === 'errorPhone'}
                topInfo={index === 0}
                noDisplay={!formInput.isActive}
                small={(formInput.name === 'errorEmail' && Object.values(inputList).length === 4)}
              >
                {formInput.label}
              </MoreInfos> :
              <MoreInfos noDisplay={!formInput.isActive}>{formInput.label}</MoreInfos>
            }
          </FormInputContainer>
        );
      }
      return (
        <FormInputContainer key={formInput.name}>
          <Label>{`${formInput.label}${formInput.isRequired ? ' *' : ''}`}</Label>
          <InputAndImgContainer>
            <FormInput
              name={formInput.name}
              value={formInput.value ?? ''}
              type={formInput.type ?? 'text'}
              size={formInput.size}
              isMobileLarge={formInput.isMobileLarge}
              onBlur={() => checkFormInputValue(formInput.name, (isAlreadyCustomer && !isLoggedIn)
                ? 'formInputsAlreadyCustomer'
                : 'formInputs',
              )}
              onChange={target => setFormInputValue(target, (isAlreadyCustomer && !isLoggedIn)
                ? 'formInputsAlreadyCustomer'
                : 'formInputs',
              )}
              error={formInput.error}
              disabled={addingNewAddress &&
                (formInput.name === 'email'
                || (formInput.name === 'phone' && customerHasPhone)
                || formInput.name === 'firstname'
                || formInput.name === 'lastname')
                ? 'disabled' : false}
            />{formInput.error !== undefined && <I error={formInput.error} />}
          </InputAndImgContainer>
          {formInput.more && formInput.more.isActive && (formInput.name !== 'email'
            ? <MoreInfos>{formInput.more.value}</MoreInfos>
            : (
              <MoreInfos>
                Cette adresse est déjà utilisée. Souhaitez-vous vous{' '}
                <UnderlinedSpan onClick={setIsAlreadyCustomerWhenEmailChecked}>
                  connecter
                </UnderlinedSpan>
                {' '}?
              </MoreInfos>
            )
          )}
        </FormInputContainer>
      );
    })}
    {isAlreadyCustomer && !isLoggedIn && (
      <TextLink
        grey left
        onClick={showPasswordForgottenModal}
      >
        Mot de passe oublié
      </TextLink>
    )}
    {!isAlreadyCustomer && !customerHasSubscribedToNewsletter && (
      <SubscribeContainer>
        {renderCheckbox(isSubscribingToNewsletter, toggleSubscribeToNewsletter)}
        <OptInText>
          J’accepte de recevoir des mails d’informations commerciales
        </OptInText>
      </SubscribeContainer>
    )}
  </FormContainer>
);

ContactForm.propTypes = {
  inputList: PropTypes.shape({}).isRequired,
  addingNewAddress: PropTypes.bool.isRequired,
  checkFormInputValue: PropTypes.func.isRequired,
  setFormInputValue: PropTypes.func.isRequired,
  setIsAlreadyCustomerWhenEmailChecked: PropTypes.func.isRequired,
  isAlreadyCustomer: PropTypes.bool.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  customerHasPhone: PropTypes.bool.isRequired,
  customerHasSubscribedToNewsletter: PropTypes.bool.isRequired,
  showPasswordForgottenModal: PropTypes.func,
  isSubscribingToNewsletter: PropTypes.bool,
  toggleSubscribeToNewsletter: PropTypes.func,
};

ContactForm.defaultProps = {
  showPasswordForgottenModal() {},
  isSubscribingToNewsletter: false,
  toggleSubscribeToNewsletter() {},
};

export default ContactForm;
