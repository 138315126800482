import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { mobileThresholdPixels, colors } from '../home/v3/styledComponents';

const BulletContainer = styled.div`
    height: 30px;
    width: 30px;
    border-radius: 50%;
    display: flex;
    background: ${colors.white};
    border: 0.25px solid ${colors.lightGrey2};
    box-sizing: border-box;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
    margin-right: 24px;
  @media (max-width: ${mobileThresholdPixels}) {
    height: 20px;
    width: 20px;
  }
`;

const ActiveBullet = styled.div`
    align-items: center;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    margin: auto;
    background: ${props => props.isActive ? colors.navy : colors.white};
  @media (max-width: ${mobileThresholdPixels}) {
    height: 8px;
    width: 8px;
  }
`;

const AddressBullet = ({ isActive }) => (
  <BulletContainer>
    <ActiveBullet isActive={isActive} />
  </BulletContainer>
);

AddressBullet.propTypes = {
  isActive: PropTypes.bool,
};

AddressBullet.defaultProps = {
  isActive: false,
};

export default AddressBullet;
