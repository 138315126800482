import housseSimple from '../../assets/order/creations/illustrations/housse_simple.jpg';
import housseOriginale from '../../assets/order/creations/illustrations/housse_originale.jpg';
import pochetteSimple from '../../assets/order/creations/illustrations/pochette_simple.jpg';
import pochetteOriginale from '../../assets/order/creations/illustrations/pochette_originale.jpg';
import sacSimple from '../../assets/order/creations/illustrations/sac_simple.jpg';
import sacOriginale from '../../assets/order/creations/illustrations/sac_originale.jpg';
import toteBagSimple from '../../assets/order/creations/illustrations/tote_bag_simple.jpg';
import toteBagOriginal from '../../assets/order/creations/illustrations/tote_bag_original.jpg';

const treeCreation = [
  {
    name: 'Totebag',
    slug: 'crea_tote',
    questions: [{
      slug: 'shape',
      label: 'La forme de votre accessoire sera plutôt :',
      answers: [
        {
          slug: 'SIMPLE',
          label: 'Simple',
          image: toteBagSimple,
        },
        {
          slug: 'COMPLEX',
          label: 'Originale',
          image: toteBagOriginal,
        },
      ],
    },
    {
      slug: 'size',
      label: 'Quelle sera la taille de votre accessoire ?',
      answers: [
        {
          slug: 'SMALL',
          label: 'Classique (35x45cm)',
          alterations: {
            SIMPLE_SMALL: '7.crea_tote-dime-1',
            COMPLEX_SMALL: '7.crea_tote-dime-3',
          },
        },
        {
          slug: 'BIG',
          label: 'Grand (40x50cm)',
          alterations: {
            SIMPLE_BIG: '7.crea_tote-dime-2',
            COMPLEX_BIG: '7.crea_tote-dime-4',
          },
        },
      ],
    },
    {
      slug: 'handle',
      label: "Quel matériau préférez-vous pour l'anse du sac ?",
      answers: [
        {
          slug: 'chainHandle',
          label: 'En chaine',
          alterations: {
            SIMPLE_SMALL: '7.crea_tote-anse-1',
            SIMPLE_BIG: '7.crea_tote-anse-2',
            COMPLEX_SMALL: '7.crea_tote-anse-7',
            COMPLEX_BIG: '7.crea_tote-anse-8',
          },
        },
        {
          slug: 'fabricHandle',
          label: 'En tissu',
          alterations: {
            SIMPLE_SMALL: '7.crea_tote-anse-3',
            SIMPLE_BIG: '7.crea_tote-anse-4',
            COMPLEX_SMALL: '7.crea_tote-anse-9',
            COMPLEX_BIG: '7.crea_tote-anse-10',
          },
        },
        {
          slug: 'otherHandle',
          label: 'Autre*',
          alterations: {
            SIMPLE_SMALL: '7.crea_tote-anse-5',
            SIMPLE_BIG: '7.crea_tote-anse-6',
            COMPLEX_SMALL: '7.crea_tote-anse-11',
            COMPLEX_BIG: '7.crea_tote-anse-12',
          },
        },
        {
          slug: 'noHandle',
          label: 'Aucune',
        },
      ],
    },
    {
      slug: 'closing',
      label: 'Quelle type de fermeture souhaitez-vous ?',
      answers: [
        {
          slug: 'buttonClosing',
          label: 'Avec bouton classique',
          alterations: {
            SIMPLE_SMALL: '7.crea_tote-ferm-5',
            SIMPLE_BIG: '7.crea_tote-ferm-6',
            COMPLEX_SMALL: '7.crea_tote-ferm-11',
            COMPLEX_BIG: '7.crea_tote-ferm-12',
          },
        },
        {
          slug: 'pressStudClosing',
          label: 'Avec bouton pression',
          alterations: {
            SIMPLE_SMALL: '7.crea_tote-ferm-3',
            SIMPLE_BIG: '7.crea_tote-ferm-4',
            COMPLEX_SMALL: '7.crea_tote-ferm-9',
            COMPLEX_BIG: '7.crea_tote-ferm-10',
          },
        },
        {
          slug: 'zipClosing',
          label: 'Avec une fermeture éclair',
          alterations: {
            SIMPLE_SMALL: '7.crea_tote-ferm-1',
            SIMPLE_BIG: '7.crea_tote-ferm-2',
            COMPLEX_SMALL: '7.crea_tote-ferm-7',
            COMPLEX_BIG: '7.crea_tote-ferm-8',
          },
        },
        {
          slug: 'noClosing',
          label: 'Aucun',
        },
      ],
    }],
  },


  // *****************************************************
  // ************************ SAC ************************
  // *****************************************************


  {
    name: 'Sac',
    slug: 'crea_sac',
    questions: [{
      slug: 'shape',
      label: 'La forme de votre accessoire sera plutôt :',
      answers: [
        {
          slug: 'SIMPLE',
          label: 'Simple',
          image: sacSimple,
        },
        {
          slug: 'COMPLEX',
          label: 'Originale',
          image: sacOriginale,
        },
      ],
    },
    {
      slug: 'size',
      label: 'Quelle sera la taille de votre accessoire ?',
      answers: [
        {
          slug: 'SMALL',
          label: 'Petit 20x30cm',
          alterations: {
            SIMPLE_SMALL: '7.crea_sac-dime-1',
            COMPLEX_SMALL: '7.crea_sac-dime-4',
          },
        },
        {
          slug: 'MEDIUM',
          label: 'Moyen 30x30cm',
          alterations: {
            SIMPLE_MEDIUM: '7.crea_sac-dime-2',
            COMPLEX_MEDIUM: '7.crea_sac-dime-5',
          },
        },
        {
          slug: 'BIG',
          label: 'Grand >30cm',
          alterations: {
            SIMPLE_BIG: '7.crea_sac-dime-3',
            COMPLEX_BIG: '7.crea_sac-dime-6',
          },
        },
      ],
    },
    {
      slug: 'handle',
      label: "Quel matériau préférez-vous pour l'anse du sac ?",
      answers: [
        {
          slug: 'chainHandle',
          label: 'En chaine',
          alterations: {
            SIMPLE_SMALL: '7.crea_sac-anse-1',
            SIMPLE_MEDIUM: '7.crea_sac-anse-2',
            SIMPLE_BIG: '7.crea_sac-anse-3',
            COMPLEX_SMALL: '7.crea_sac-anse-10',
            COMPLEX_MEDIUM: '7.crea_sac-anse-11',
            COMPLEX_BIG: '7.crea_sac-anse-12',
          },
        },
        {
          slug: 'fabricHandle',
          label: 'En tissu',
          alterations: {
            SIMPLE_SMALL: '7.crea_sac-anse-4',
            SIMPLE_MEDIUM: '7.crea_sac-anse-5',
            SIMPLE_BIG: '7.crea_sac-anse-6',
            COMPLEX_SMALL: '7.crea_sac-anse-13',
            COMPLEX_MEDIUM: '7.crea_sac-anse-14',
            COMPLEX_BIG: '7.crea_sac-anse-15',
          },
        },
        {
          slug: 'otherHandle',
          label: 'Autre*',
          alterations: {
            SIMPLE_SMALL: '7.crea_sac-anse-7',
            SIMPLE_MEDIUM: '7.crea_sac-anse-8',
            SIMPLE_BIG: '7.crea_sac-anse-9',
            COMPLEX_SMALL: '7.crea_sac-anse-16',
            COMPLEX_MEDIUM: '7.crea_sac-anse-17',
            COMPLEX_BIG: '7.crea_sac-anse-18',
          },
        },
        {
          slug: 'noHandle',
          label: 'Aucune',
        },
      ],
    },
    {
      slug: 'closing',
      label: 'Quelle type de fermeture souhaitez-vous ?',
      answers: [
        {
          slug: 'buttonClosing',
          label: 'Avec bouton classique',
          alterations: {
            SIMPLE_SMALL: '7.crea_sac-ferm-7',
            SIMPLE_MEDIUM: '7.crea_sac-ferm-8',
            SIMPLE_BIG: '7.crea_sac-ferm-9',
            COMPLEX_SMALL: '7.crea_sac-ferm-16',
            COMPLEX_MEDIUM: '7.crea_sac-ferm-17',
            COMPLEX_BIG: '7.crea_sac-ferm-18',
          },
        },
        {
          slug: 'pressStudClosing',
          label: 'Avec bouton pression',
          alterations: {
            SIMPLE_SMALL: '7.crea_sac-ferm-4',
            SIMPLE_MEDIUM: '7.crea_sac-ferm-5',
            SIMPLE_BIG: '7.crea_sac-ferm-6',
            COMPLEX_SMALL: '7.crea_sac-ferm-13',
            COMPLEX_MEDIUM: '7.crea_sac-ferm-14',
            COMPLEX_BIG: '7.crea_sac-ferm-15',
          },
        },
        {
          slug: 'zipClosing',
          label: 'Avec une fermeture éclair',
          alterations: {
            SIMPLE_SMALL: '7.crea_sac-ferm-1',
            SIMPLE_MEDIUM: '7.crea_sac-ferm-2',
            SIMPLE_BIG: '7.crea_sac-ferm-3',
            COMPLEX_SMALL: '7.crea_sac-ferm-10',
            COMPLEX_MEDIUM: '7.crea_sac-ferm-11',
            COMPLEX_BIG: '7.crea_sac-ferm-12',
          },
        },
        {
          slug: 'noClosing',
          label: 'Aucun',
        },
      ],
    }],
  },


  // **********************************************
  // ****************** POCHETTE ******************
  // **********************************************


  {
    name: 'Pochette',
    slug: 'crea_poch',
    questions: [{
      slug: 'shape',
      label: 'La forme de votre accessoire sera plutôt :',
      answers: [
        {
          slug: 'SIMPLE',
          label: 'Simple',
          image: pochetteSimple,
        },
        {
          slug: 'COMPLEX',
          label: 'Originale',
          image: pochetteOriginale,
        },
      ],
    },
    {
      slug: 'size',
      label: 'Quelle sera la taille de votre accessoire ?',
      answers: [
        {
          slug: 'SMALL',
          label: 'Petit 15x10cm',
          alterations: {
            SIMPLE_SMALL: '7.crea_poch-dime-1',
            COMPLEX_SMALL: '7.crea_poch-dime-4',
          },
        },
        {
          slug: 'MEDIUM',
          label: 'Moyen 20x20cm',
          alterations: {
            SIMPLE_MEDIUM: '7.crea_poch-dime-2',
            COMPLEX_MEDIUM: '7.crea_poch-dime-5',
          },
        },
        {
          slug: 'BIG',
          label: 'Grand >25cm',
          alterations: {
            SIMPLE_BIG: '7.crea_poch-dime-3',
            COMPLEX_BIG: '7.crea_poch-dime-6',
          },
        },
      ],
    },
    {
      slug: 'handle',
      label: "Quel matériau préférez-vous pour l'anse de la pochette?",
      answers: [
        {
          slug: 'chainHandle',
          label: 'En chaine',
          alterations: {
            SIMPLE_SMALL: '7.crea_poch-anse-1',
            SIMPLE_MEDIUM: '7.crea_poch-anse-2',
            SIMPLE_BIG: '7.crea_poch-anse-3',
            COMPLEX_SMALL: '7.crea_poch-anse-10',
            COMPLEX_MEDIUM: '7.crea_poch-anse-11',
            COMPLEX_BIG: '7.crea_poch-anse-12',
          },
        },
        {
          slug: 'fabricHandle',
          label: 'En tissu',
          alterations: {
            SIMPLE_SMALL: '7.crea_poch-anse-4',
            SIMPLE_MEDIUM: '7.crea_poch-anse-5',
            SIMPLE_BIG: '7.crea_poch-anse-6',
            COMPLEX_SMALL: '7.crea_poch-anse-13',
            COMPLEX_MEDIUM: '7.crea_poch-anse-14',
            COMPLEX_BIG: '7.crea_poch-anse-15',
          },
        },
        {
          slug: 'otherHandle',
          label: 'Autre*',
          alterations: {
            SIMPLE_SMALL: '7.crea_poch-anse-7',
            SIMPLE_MEDIUM: '7.crea_poch-anse-8',
            SIMPLE_BIG: '7.crea_poch-anse-9',
            COMPLEX_SMALL: '7.crea_poch-anse-16',
            COMPLEX_MEDIUM: '7.crea_poch-anse-17',
            COMPLEX_BIG: '7.crea_poch-anse-18',
          },
        },
        {
          slug: 'noHandle',
          label: 'Aucune',
        },
      ],
    },
    {
      slug: 'closing',
      label: 'Quelle type de fermeture souhaitez-vous ?',
      answers: [
        {
          slug: 'buttonClosing',
          label: 'Avec bouton classique',
          alterations: {
            SIMPLE_SMALL: '7.crea_poch-ferm-7',
            SIMPLE_MEDIUM: '7.crea_poch-ferm-8',
            SIMPLE_BIG: '7.crea_poch-ferm-9',
            COMPLEX_SMALL: '7.crea_poch-ferm-16',
            COMPLEX_MEDIUM: '7.crea_poch-ferm-17',
            COMPLEX_BIG: '7.crea_poch-ferm-18',
          },
        },
        {
          slug: 'pressStudClosing',
          label: 'Avec bouton pression',
          alterations: {
            SIMPLE_SMALL: '7.crea_poch-ferm-4',
            SIMPLE_MEDIUM: '7.crea_poch-ferm-5',
            SIMPLE_BIG: '7.crea_poch-ferm-6',
            COMPLEX_SMALL: '7.crea_poch-ferm-13',
            COMPLEX_MEDIUM: '7.crea_poch-ferm-14',
            COMPLEX_BIG: '7.crea_poch-ferm-15',
          },
        },
        {
          slug: 'zipClosing',
          label: 'Avec une fermeture éclair',
          alterations: {
            SIMPLE_SMALL: '7.crea_poch-ferm-1',
            SIMPLE_MEDIUM: '7.crea_poch-ferm-2',
            SIMPLE_BIG: '7.crea_poch-ferm-3',
            COMPLEX_SMALL: '7.crea_poch-ferm-10',
            COMPLEX_MEDIUM: '7.crea_poch-ferm-11',
            COMPLEX_BIG: '7.crea_poch-ferm-12',
          },
        },
        {
          slug: 'noClosing',
          label: 'Aucun',
        },
      ],
    }],
  },


  // *****************************************************
  // *********************** HOUSSE **********************
  // *****************************************************


  {
    name: 'Housse',
    slug: 'crea_hous',
    questions: [{
      slug: 'shape',
      label: 'La forme de votre accessoire sera plutôt :',
      answers: [
        {
          slug: 'SIMPLE',
          label: 'Simple',
          image: housseSimple,
        },
        {
          slug: 'COMPLEX',
          label: 'Originale',
          image: housseOriginale,
        },
      ],
    },
    {
      slug: 'size',
      label: 'Quelle sera la taille de votre accessoire ?',
      answers: [
        {
          slug: 'SMALL',
          label: 'Petit (<45cm)',
          alterations: {
            SIMPLE_SMALL: '7.crea_hous-dime-1',
            COMPLEX_SMALL: '7.crea_hous-dime-3',
          },
        },
        {
          slug: 'BIG',
          label: 'Grand (>45cm)',
          alterations: {
            SIMPLE_BIG: '7.crea_hous-dime-2',
            COMPLEX_BIG: '7.crea_hous-dime-4',
          },
        },
      ],
    },
    {
      slug: 'closing',
      label: 'Quelle type de fermeture souhaitez-vous ?',
      answers: [
        {
          slug: 'buttonClosing',
          label: 'Avec bouton classique',
          alterations: {
            SIMPLE_SMALL: '7.crea_hous-ferm-5',
            SIMPLE_BIG: '7.crea_hous-ferm-6',
            COMPLEX_SMALL: '7.crea_hous-ferm-11',
            COMPLEX_BIG: '7.crea_hous-ferm-12',
          },
        },
        {
          slug: 'invisibleZip',
          label: 'Avec un zip invisible',
          alterations: {
            SIMPLE_SMALL: '7.crea_hous-ferm-3',
            SIMPLE_BIG: '7.crea_hous-ferm-4',
            COMPLEX_SMALL: '7.crea_hous-ferm-9',
            COMPLEX_BIG: '7.crea_hous-ferm-10',
          },
        },
        {
          slug: 'zipClosing',
          label: 'Avec une fermeture éclair',
          alterations: {
            SIMPLE_SMALL: '7.crea_hous-ferm-1',
            SIMPLE_BIG: '7.crea_hous-ferm-2',
            COMPLEX_SMALL: '7.crea_hous-ferm-7',
            COMPLEX_BIG: '7.crea_hous-ferm-8',
          },
        },
        {
          slug: 'noClosing',
          label: 'Aucun',
        },
      ],
    }],
  },
];

export default treeCreation;
